import { useEffect, useMemo, useState } from 'react';
import { Page } from '@dentsu-ui/components';
import { BriefList } from './components/BriefList';
import {Summary} from './components/Summary';
import { FETCH_PLAN_LIST } from './graphql';
import { mockColumns } from '__mocks__/mockData';
import { useQuery } from '@apollo/client';
import Cascader from '../../components/Cascader';
import withClientHierarchy from '../../hoc/withClientHierarchy';
import { getAllOriginalId, getClientCurrency } from 'modules/helper';
import { useClientLogo, useBriefList, useClientBanner } from 'hooks';
import { Helmet } from 'react-helmet';
import { CreateBrief } from 'modules/CreateBrief';
import { useAtom } from 'jotai';
import { createBriefAtom } from 'store';

/**
 * Client Portal - Dashboard
 */

const Dashboard = props => {
  // props values :
  const { setHierarchyHandler, hierarchyData: clientStructure, clientCode, clientName } = props;

  // Mock data
  const initialFilterOptions = { H1: '', H2: '', agency: '' };
  const intitialfinalFilter = { H1: '', H2: '', agency: '' };
  const briefListColumns = useMemo(() => mockColumns, []);
  // local state
  const [briefPlanList, setBriefPlanlist] = useState([]);
  const [filterOptions, setFilter] = useState(initialFilterOptions);
  const [finalFilter, setFinalFilter] = useState(intitialfinalFilter);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [ summeryFilter , setsSummeryFilter ] = useState({});

  const [, setCreateBriefWizard] = useAtom(createBriefAtom);

  useEffect(() => {
    if(clientStructure){
    const { H1 , H2 } = clientStructure;
    let res;
    let res1;
    if (filterOptions?.H2 && finalFilter.H2) {
      res = filterOptions?.H2.find(filter => filter.value === finalFilter.H2);
    }
    if (filterOptions?.H1 && finalFilter.H1) {
      res1 = filterOptions?.H1.find(filter => filter.value === finalFilter.H1);
    }
    let h1 = filterOptions.H1 && finalFilter.H1 ? res1.unrolledAccountIds : H1.unrolledAccountIds;
    let h2 = filterOptions.H2 && finalFilter.H2 ? res.unrolledAccountIds : H2.unrolledAccountIds;
    setHierarchyHandler(h1, h2, '*');
    if (refetch) {
      refetch();
    }
    }
  }, [JSON.stringify(clientStructure), clientCode, JSON.stringify(finalFilter)]);

  //BRIEF LIST HOOK
  const { briefDataList, loading, refetch, totalCount , setBriefPageSize , briefPageSize} = useBriefList();

  //GET CLIENT BANNER HOOK
  const { banner } = useClientBanner('');
  // GET CLIENT LOGO HOOK
  const { thumbnail } = useClientLogo('');

  // FETCHING FETCH_PLAN_LIST
  const originalId = getAllOriginalId(briefDataList);
  useQuery(FETCH_PLAN_LIST, {
    variables: {
      targetCurrency: getClientCurrency(clientStructure),
      briefIds: originalId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: result => handleBriefPlanList(result),
  });
  const handleBriefPlanList = data => {
    const planList = data && data.getCLPPlanSummaryOfBrief;
    setBriefPlanlist(planList || []);
  };

  const handleCloseCascader = () => setShouldRefetch(true);


  const geoProps = {
    value: finalFilter.H1,
    setHierarchy: value => setFilter({ ...filterOptions, H1: value }),
    handleSave: value => setFinalFilter({ ...finalFilter, H1: value }),
    handleSummery:value => setsSummeryFilter({H1:value}),
    handleClose: handleCloseCascader,
    type: 'location',
    label: 'Location',
    levels: ['Location'],
  };

  const brandProps = {
    value: finalFilter.H2,
    setHierarchy: value => setFilter({ ...filterOptions, H2: value }),
    handleSave: value => setFinalFilter({ ...finalFilter, H2: value }),
    handleClose: handleCloseCascader,
    type: 'brand',
    label: 'Brand',
    levels: ['Brand', 'Division', 'Business Group', 'Business Unit', 'Product Group', 'Product'],
  };

  // Markup to return
  return (
    <>
      <Helmet title="Client Portal" />
      <CreateBrief clientName={clientName} />
      <Page
        title={clientName}
        description="Stay on top of your briefs and latest developments with your media campaigns by selecting a brief below."
        banner={banner}
        thumbnail={thumbnail}
        thumbnailFallback={`/images/default_logo.png`}
        primaryAction={{
          content: 'Create a brief',
          onClick: () => setCreateBriefWizard(true),
          variant: 'secondary',
        }}
        controls={
          <>
            <Cascader {...brandProps} />
            <Cascader {...geoProps}  />
          </>
        }
      >
        <Summary currency={getClientCurrency(clientStructure)} hasChart={finalFilter?.H1} clientStructure={clientStructure} finalFilter={summeryFilter} />
        <BriefList
          columns={briefListColumns}
          planData={briefPlanList}
          briefPageSize={briefPageSize}
          setBriefPageSize={setBriefPageSize}
          briefIdList={originalId}
          listLoading={loading}
          clientName={clientName}
          data={briefDataList}
          refetch={refetch}
          totalCount={totalCount}
          clientHierarchy={clientStructure}
        />
      </Page>
    </>
  );
};

export default withClientHierarchy(Dashboard);
