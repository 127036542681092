import styled, { css } from 'styled-components';

const fontSizes = {
  small: 'var(--dentsu-font-size-12)',
  medium: 'var(--dentsu-font-size-14)',
  large: 'var(--dentsu-font-size-16)',
};

const lineHeights = {
  small: 'var(--dentsu-leading-tight)',
  medium: 'var(--dentsu-leading-tight)',
  large: 'var(--dentsu-leading-normal)',
};

const DescriptionList = styled.dl`
  --dt-block-width: max(25%, 110px);
  --dd-block-width: max(calc(100% - var(--dt-block-width)), 110px);

  width: ${({ isInline }) => (isInline ? 'auto' : '100%')};
  display: flex;
  align-items:center;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
`;

const Term = styled.dt`
  font-size: ${({ fontSize }) => fontSizes[fontSize]};
  line-height: ${({ fontSize }) => lineHeights[fontSize]};
  word-break: break-word;
  ${({ isInline }) =>
    isInline
      ? css`
          width: auto;
          margin-block: 0;
          margin-inline: 0 var(--dentsu-spacing-4);
          padding-block: var(--dentsu-spacing-2);
          padding-inline: 0;
        `
      : css`
          width: var(--dt-block-width);
          margin-block: 0 var(--dentsu-spacing-4);
          margin-inline: 0;
          padding-block: ${({ hasSeparator }) => (hasSeparator ? '0 var(--dentsu-spacing-4)' : '0')};
          padding-inline: 0;
          border-bottom: ${({ hasSeparator }) => (hasSeparator ? '1px solid var(--dentsu-color-n300)' : 'none')};

          &:last-of-type {
            border-bottom: ${({ hasEndSeparator }) =>
              hasEndSeparator ? '1px solid var(--dentsu-color-n300)' : 'none'};
            margin-block: 0;
          }
        `};
`;

const Detail = styled.dd`
  font-size: ${({ fontSize }) => fontSizes[fontSize]};
  line-height: ${({ fontSize }) => lineHeights[fontSize]};
  word-break: break-word;
  ${({ isInline }) =>
    isInline
      ? css`
          width: auto;
          margin-block: 0;
          margin-inline: 0 var(--dentsu-spacing-12);
          padding-block: var(--dentsu-spacing-2);
          padding-inline: ${({ hasSeparator }) => (hasSeparator ? '0 var(--dentsu-spacing-12)' : '0')};
          border-right: ${({ hasSeparator }) => (hasSeparator ? '1px solid var(--dentsu-color-n300)' : 'none')};

          &:last-of-type {
            border-right: ${({ hasEndSeparator }) => (hasEndSeparator ? '1px solid var(--dentsu-color-n300)' : 'none')};
          }
        `
      : css`
          width: var(--dd-block-width);
          margin-block: 0 var(--dentsu-spacing-4);
          margin-inline: 0;
          padding-block: ${({ hasSeparator }) => (hasSeparator ? '0 var(--dentsu-spacing-4)' : '0')};
          padding-inline: 0;
          border-bottom: ${({ hasSeparator }) => (hasSeparator ? '1px solid var(--dentsu-color-n300)' : 'none')};

          &:last-of-type {
            border-bottom: ${({ hasEndSeparator }) =>
              hasEndSeparator ? '1px solid var(--dentsu-color-n300)' : 'none'};
            margin-block: 0;
          }
        `};
`;

export const Styled = { DescriptionList, Term, Detail };
