import { gql } from '@apollo/client';


//Define the Mutation for the Optional Link 
export const SAVE_OPTIONAL_LINKS = gql`
mutation saveOptionalLinks ($clientCode: String , $url:String, $label:String){
  saveOptionalLinks(clientCode: $clientCode, url: $url, label:$label){
    clientCode
    url
    label
  }
}
`;