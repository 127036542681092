import React, { Fragment } from 'react';

import { Stack, Box, Paragraph } from '@dentsu-ui/components';
import moment from 'moment';

const BasicDetails = ({ brand, marketName, briefData , campaignBudgetRef }) => {
  const startDate = moment(new Date(briefData.startDate)).format('LL');
  const endDate = moment(new Date(briefData.endDate)).format('LL');
  const responseDate = moment(new Date(briefData.responseDate)).format('LL');

  return (
    <Fragment>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]}>
          <Paragraph isBold={true}>{'Brand'}</Paragraph>
          <Paragraph>{brand}</Paragraph>
        </Box>
        <Box width={[3 / 6]}>
          <Paragraph isBold={true}>{'Overall campaign budget'}</Paragraph>
          <Paragraph ref={campaignBudgetRef}>
            {briefData.budgetAmount} {briefData.budgetCurrency}
          </Paragraph>
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]}>
          <Paragraph isBold={true}>{'Market'}</Paragraph>
          <Paragraph>{(briefData && marketName && marketName.label) || ''}</Paragraph>
        </Box>
        <Box width={[3 / 6]}>
          <Paragraph isBold={true}>{'Funding source'}</Paragraph>
          <Paragraph>{briefData.fundingSource}</Paragraph>
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} display="flex" pr="30px">
          <Box width={[2 / 6]}>
            <Paragraph isBold={true}>{'Start date'}</Paragraph>
            <Paragraph>{startDate}</Paragraph>
          </Box>
          <Box width={[2 / 6]}>
            <Paragraph isBold={true}>{'End Date'}</Paragraph>
            <Paragraph>{endDate}</Paragraph>
          </Box>
        </Box>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Response date'}</Paragraph>
          <Paragraph>{responseDate}</Paragraph>
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Lead agency'}</Paragraph>
          <Paragraph>{briefData.leadAgency}</Paragraph>
        </Box>
        <Box width={[3 / 6]}>
          <Paragraph isBold={true}>{'Brief owner'}</Paragraph>
          <Paragraph>{briefData.ownerName}</Paragraph>
        </Box>
      </Stack>
    </Fragment>
  );
};

export default BasicDetails;
