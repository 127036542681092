import { Box, Caption, FormatCurrency, FormatNumber, Stack, Statistic } from '@dentsu-ui/components';
import { PieChart as Chart } from '@dentsu-ui/charts';

export function PieChart({ currency, data , approvedBudget}) {
  return (
    <>
      <Chart
        innerRadius={0.75}
        padAngle={0.25}
        data={data}
        id="label"
        value="value"
        margin={{
          top: 32,
          right: 32,
          bottom: 32,
          left: 32,
        }}
        tooltip={({ datum: { id, value, color } }) => {
          const percentage = data.filter(d => d.label === id).map(d => d.percentage)[0];
          return (
            <Stack
              p="16px"
              bg="white"
              alignItems="center"
              style={{ gap: '4px', borderRadius: '16px', boxShadow: 'var(--dentsu-shadow-8)' }}
            >
              <Box width="12px" height="12px" bg={color} />
              <Caption isSecondary>{id}:</Caption>
              <Caption>
                <FormatCurrency currency={currency} value={value} isUnstyled />
                {' ('}
                <FormatNumber value={percentage} isUnstyled />
                {'%)'}
              </Caption>
            </Stack>
          );
        }}
        radialLabel={e => (
          <>
            <tspan x="0" dy="-0.6em">
              {e.label}
            </tspan>
            <tspan x="0" dy="1.2em">
              <FormatNumber value={e.data.percentage} isUnstyled />%
            </tspan>
          </>
        )}
        layers={[
          'slices',
          'sliceLabels',
          'radialLabels',
          'legends',
          data => {
            let total = 0;
            data.dataWithArc.forEach(datum => {
              total += datum.value;
            });
            return (
              <foreignObject
                x={data.centerX - 100}
                y={data.centerY - 30}
                width="200px"
                height="100px"
                textAnchor="middle"
                dominantBaseline="central"
                alignmentBaseline="middle"
              >
                <Statistic
                  currency={currency}
                  width={200}
                  size="medium"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  <Statistic.Label>Total approved plan budget</Statistic.Label>
                  <Statistic.Content
                    value={{
                      current: total || approvedBudget,
                      format: { isCompact: false, maximumFractionDigits: 2, style: 'currency' },
                    }}
                  />
                </Statistic>
              </foreignObject>
            );
          },
        ]}
      />
    </>
  );
}
