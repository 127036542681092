import {  useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_OPTIONAL_LINK } from 'modules/Settings/graphql';
// GQL


const useOptionalLinks = () => {
  const [label, setLabel] = useState('');
  const [link, setLink] = useState('');

    
  const handleOptionalData = data => {
    const optionalData = data && data.getOptionalLinks;
    setLabel(optionalData?.label);
    setLink(optionalData?.url);
  };
  useQuery(GET_OPTIONAL_LINK, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: data => handleOptionalData(data),
  });
   
  return {label , link , setLink , setLabel};
};

export default useOptionalLinks;
