import React from 'react';
import { Box, Caption, Divider, Avatar, Image } from '@dentsu-ui/components';
import { Styled } from './BriefCard.styles';
import { useAtom } from 'jotai';
import { clientLogoAtom } from 'store';

// BriefCard wrapper component
function BriefCard({ children, isDisabled, ...rest }) {
  return (
    <Styled.Wrapper isDisabled={isDisabled} {...rest}>
      {children}
    </Styled.Wrapper>
  );
}

// BriefCard header component
function Header({ children, owner, subtitle, thumbnail, title, uploadImage }) {
  const [defaultLogo] = useAtom(clientLogoAtom);
  return (
    <Styled.Header>
      <Box display="flex" justifyContent={'center'} alignItems={'center'}>
        <Box width="50px" height="50px">
          <Image width="50px" height="50px" src={uploadImage} objectFit="contain" fallbackSrc={defaultLogo} />
        </Box>
        <Box margin={'0 1rem 0 1.5rem'}>
          {subtitle && <Styled.Subtitle>{subtitle}</Styled.Subtitle>}
          {title && <Styled.Title>{title}</Styled.Title>}
          {owner ? (
            <Styled.Owner>
              <Caption as="span" isSecondary>
                Owner:
              </Caption>
              <Avatar style={{ margin: '0 5px' }} size="large" src={thumbnail} name={owner} />
              <Caption as="span">{owner}</Caption>
            </Styled.Owner>
          ) : (
            <Styled.Owner>
              <Caption as="span" isSecondary>
                Owner:
              </Caption>
              <Avatar style={{ margin: '0 5px' }} size="large" name={owner} />
              <Caption as="span">{owner}</Caption>
            </Styled.Owner>
          )}
          {children}
        </Box>
      </Box>
    </Styled.Header>
  );
}

// Briefcard body component
function Body({ children, ...rest }) {
  return <Styled.Body {...rest}>{children}</Styled.Body>;
}

// BriefCard column one component
function ColumnOne({ children, ...rest }) {
  return <Styled.ColumnOne {...rest}>{children}</Styled.ColumnOne>;
}

// BriefCard column two component
function ColumnTwo({ children, ...rest }) {
  return <Styled.ColumnTwo {...rest}>{children}</Styled.ColumnTwo>;
}

// BriefCard column three component
function ColumnThree({ children, ...rest }) {
  return <Styled.ColumnThree {...rest}>{children}</Styled.ColumnThree>;
}

// BriefCard footer component
function Footer({ children }) {
  return (
    <>
      <Divider style={{ marginBlock: '16px' }} />
      <Styled.Footer>{children}</Styled.Footer>
    </>
  );
}

BriefCard.Header = Header;
BriefCard.Body = Body;
BriefCard.Body.ColOne = ColumnOne;
BriefCard.Body.ColTwo = ColumnTwo;
BriefCard.Body.ColThree = ColumnThree;
BriefCard.Footer = Footer;

export { BriefCard };
