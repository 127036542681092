export const handleClientStructure = (clientStructure, keysMap) => {
  if (clientStructure == null) return null;
  if (Array.isArray(clientStructure)) {
    return clientStructure.map(item => handleClientStructure(item, keysMap));
  }
  if (typeof clientStructure === 'object') {
    if (clientStructure.sub && !clientStructure.sub.length) {
      const { sub, ...rest } = clientStructure;
      clientStructure = rest;
    }
    return Object.keys(clientStructure).reduce((obj, key) => {
      const propKey = handleClientStructure(key, keysMap);
      const propVal = handleClientStructure(clientStructure[key], keysMap);
      obj[propKey] = propVal;
      if (propKey === 'isLeaf') {
        obj.leaf = obj.isLeaf;
      }
      if (propKey === 'accountId') {
        obj.originalId = obj.accountId;
      }
      return obj;
    }, {});
  }
  if (typeof clientStructure === 'string') {
    return keysMap[clientStructure] || clientStructure;
  }
  return clientStructure;
};

export const getLevelName = (geoOrProdData, levels = []) => {
  const { levelName = '', children = [] } = geoOrProdData;
  levels.push(levelName);
  if (children.length) {
    children.forEach(data => getLevelName(data, levels));
  }
  return [...new Set(levels)];
};

export const getFilteredAgency = (agencies, levelName, label) => {
  const mainArr = [];
  const newObj = {
    levelName,
    label,
    value: "agency"
  };
  const { data } = agencies;
  for ( const element of data ) {
    const { name, code, countries, ...rest } = element;
    const obj = rest
    obj.label = name;
    obj.levelName = code;
    obj.value = code;
    mainArr.push(obj);
  }
  newObj.children = mainArr;
  return newObj;
}
