import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  FormatNumber,
  FormatDateTime,
  Page,
  Stack,
  Caption,
  Button,
  Paragraph,
} from '@dentsu-ui/components';
import { DescriptionList } from 'components';
import { clientHierarchyFormatter } from '../BriefDetail/helper';
import { GET_BRIEF } from '../BriefDetail/graphql';
import { useQuery } from '@apollo/client';
import Loader from 'components/Loader';
import { useClientHierarchy } from 'hooks';
import { BriefViewDetail } from 'modules/BriefViewDetail';
import { getBrandLocation, getFormatDate } from 'modules/helper';
import DeliverableTab from './components/DeliverableTab';
import { GET_CLIENT_DELIVERABLES } from './graphql';

/**
 * Client portal  - Brief Detail Page
 */

export function CLPBriefDetails() {
  const [isTakeOverOpen, setIsTakeOverOpen] = useState(false);
  const [deliverableList, setDeliverableList] = useState([]);

  // useClientHierarchy Hooks
  const { clientStructure } = useClientHierarchy({});

  // Brief ID from URL path
  const { id: briefId } = useParams();

  // GET_CLIENT_DELIVERABLES

  const handleClientDeliverablesData = data => {
    // setDeliverableList([])
    const deliverableData = data && data.getClientDeliverableAndAttentionStatsByBrief;
    if (!deliverableData) {
      setDeliverableList([]);
      return;
    }
    setDeliverableList(deliverableData?.data);
  };

  const {
    error: clientDeliverableDataError,
    loading: clientDeliverableLoading,
    refetch,
  } = useQuery(GET_CLIENT_DELIVERABLES, {
    variables: {
      deliverableListParams: {
        briefId: briefId,
      },
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: data => handleClientDeliverablesData(data),
  });

  // GET_BRIEF  query
  const { data: { getBrief: { data: briefData = {} } = {} } = {}, error: briefError } = useQuery(GET_BRIEF, {
    variables: { id: briefId },
    fetchPolicy: 'cache-and-network',
  });
  const { budgetAmount, budgetCurrency, endDate, name, ownerName, startDate, h2, responseDate } = briefData.brief || {};
  let usersEmail = new Set();

  let marketName = '';
  if (briefData?.brief && clientStructure) {
    const { h1: H1 } = briefData.brief;
    usersEmail.add(briefData.brief.ownerEmail);
    marketName = clientHierarchyFormatter(H1, clientStructure.H1);
  }
  // Brand Name :
  const brand = getBrandLocation(h2, clientStructure?.H2);

  // Markup to return
  if (!briefData.brief) return <Loader />;
  // if (clientDeliverableLoading) return <Loader />;

  const error = briefError || clientDeliverableDataError;
  if (error) return <Loader title={'something went wrong!'} />;

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Back to briefs',
            url: '/',
            as: RouterLink,
          },
        ]}
        // metadata="Market"
        metadata={<Caption isSecondary>{briefData?.brief && marketName && marketName.label}</Caption>}
        title={name}
      >
        <Box width="60%">
          <Stack alignItems="center" mb="10px">
            <DescriptionList fontSize="medium" isInline hasSeparator>
              <DescriptionList.Term>Brand:</DescriptionList.Term>
              <DescriptionList.Detail>{brand}</DescriptionList.Detail>
              <DescriptionList.Term>Market:</DescriptionList.Term>
              <DescriptionList.Detail>{marketName.label}</DescriptionList.Detail>

              <DescriptionList.Term>Owned by:</DescriptionList.Term>
              <DescriptionList.Detail>{ownerName}</DescriptionList.Detail>
            </DescriptionList>
          </Stack>
          <Divider />
          <Stack alignItems="center" mb="16px">
            <DescriptionList fontSize="medium" isInline hasSeparator>
              <Box display="flex" justifyContent="space-between" mr="10px">
                <Paragraph style={{ marginRight: '10px' }} isBold>
                  {' '}
                  Briefed
                </Paragraph>
                <DescriptionList.Term> Budget:</DescriptionList.Term>
              </Box>
              <DescriptionList.Detail>
                {budgetCurrency} <FormatNumber value={budgetAmount} isUnstyled />
              </DescriptionList.Detail>
              <DescriptionList.Term>Duration:</DescriptionList.Term>
              <DescriptionList.Detail>
                {startDate && <FormatDateTime isUnstyled mode="local" format="date" value={startDate} />}
                {' — '}
                {endDate && <FormatDateTime isUnstyled mode="local" format="date" value={endDate} />}
              </DescriptionList.Detail>
              <DescriptionList.Term>Due date:</DescriptionList.Term>
              <DescriptionList.Detail>
                {responseDate ? getFormatDate(responseDate) : `${undefined}`}
              </DescriptionList.Detail>

              <DescriptionList.Term>
                <Button variant="ghost" size="small" iconRight="share" onClick={() => setIsTakeOverOpen(true)}>
                  View Brief
                </Button>
              </DescriptionList.Term>
              <DescriptionList.Detail></DescriptionList.Detail>
            </DescriptionList>
          </Stack>
        </Box>
        <DeliverableTab
          deliverableList={deliverableList}
          loading={clientDeliverableLoading}
          briefName={name}
          briefId={briefId}
          refetch={refetch}
        />
      </Page>
      <BriefViewDetail
        setIsTakeOverOpen={setIsTakeOverOpen}
        isTakeOverOpen={isTakeOverOpen}
        brand={brand}
        marketName={marketName}
        briefData={briefData}
      />
    </>
  );
}
