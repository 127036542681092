import { gql } from '@apollo/client';


const GET_CLIENT_DELIVERABLES = gql`
  query getClientDeliverableAndAttentionStatsByBrief($deliverableListParams : getDeliverableParams) {
    getClientDeliverableAndAttentionStatsByBrief(deliverableListParams: $deliverableListParams) {
      data {
        count
        list {
          lastUpdatedBy{
            displayName
          }
          channelGroup
          clientApprovalStatus
          name
          originalId
          plan
          reviews {
            sharedBy {
              displayName
            }
            sharedAt
            reviewDate
          }
          type {
            type
            typeCode
          }
        }
        stats{
          count
          type
          typeCode
        }
      }
    }
  }`;

  export { GET_CLIENT_DELIVERABLES };
