import gql from "graphql-tag";

export const GET_CLIENT_SUMMARY = gql`
query{
  getClientSummary{
    status
    message
    data {
      id
      contractType
      name
      country{
        name
        code
      }
      sector {
        name
        code
      }
      leadAgency{
        name
        code
      }
      markets {
        name
        code
      }
      type
      supportingAgencies {
        code
        name
      }
      contractDuration{
        type
        from
        to
        renewPeriod
      }
      clientContacts{
        name
        jobTitle
        email
        type
      }
      progress
      statuses{
        finance
        legal
        self
      }
      bulkUpload
      productAcknowledged
    }
  }
}
`
