import { useCallback, useState, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Layout, FormField, TextInput, Button, Toast } from '@dentsu-ui/components';
import { GET_OPTIONAL_LINK, SAVE_OPTIONAL_LINKS } from './graphql';
import useOptionalLinks from 'hooks/useOptionalLinks';
import { validate } from 'modules/helper';

export function OptionalLink() {
  const { label, link, setLabel, setLink } = useOptionalLinks();
  const [urlError, setUrlError] = useState({});

  const linkRef = useRef();
  const toast = Toast();

  //mutation for the SAVE_OPTIONAL_LINKS
  const [createLink, { error: linkError }] = useMutation(SAVE_OPTIONAL_LINKS);
  const handleLink = useCallback(async () => {
    await createLink({
      variables: {
        label: label,
        url: link,
      },
    });
    setUrlError(validate(link, linkError));
  }, [createLink, link, label, linkError]);

  const onLinkChange = e => {
    setLink(e.target.value);
    linkRef.current = e.target.value;
    if (linkRef.current === '') {
      setUrlError('URL is required!');
    } else {
      setUrlError('');
    }
  };

  return (
    <Layout>
      <Layout.Section>
        <Layout.Panel title="Optional Link">
          <FormField label="Link label">
            <TextInput
              placeholder="Explore the dentsu network"
              value={label}
              type="text"
              onChange={e => setLabel(e.target.value)}
            />
          </FormField>
          <FormField
            label="Link URL"
            hint="E.g. https://globalappsportal.sharepoint.com/sites/MediaVault"
            error={urlError.link}
          >
            <TextInput placeholder="https://" value={link} type="text" onChange={onLinkChange} />
          </FormField>
          <Button variant="primary" onClick={handleLink}>
            Update
          </Button>
        </Layout.Panel>
      </Layout.Section>
    </Layout>
  );
}
