import queryString from 'query-string';

//FOR THE SERVICE CONFIG
export const  getServiceConfig = ()  =>{
    const appUrlSearch = window && window.location && window.location.search;
    if (appUrlSearch) {
      const urlParams = queryString.parse(appUrlSearch);
      const { clientCode = '', tenantId = '' } = urlParams;
      if (urlParams) {
        const serviceConfig = {
          clientCode,
          tenantId,
        };
        sessionStorage.setItem('serviceConfig', JSON.stringify(serviceConfig));
      }
    }
  }

  export const clientCode = JSON.parse(localStorage.getItem('pitch_client_code'));
  export const tenantId = JSON.parse(localStorage.getItem('pitch_tenant_id'));
