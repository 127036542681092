import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Page } from '@dentsu-ui/components';
import { BriefList } from './components/BriefList';
import { ClientStructureModal } from './components/ClientStructure';
import Cascader from '../../components/Cascader';
import { Helmet } from 'react-helmet';

// Mock data
import { mockColumns } from '__mocks__/mockData';
import useBriefList from 'hooks/useBriefList';
import withClientHierarchy from 'hoc/withClientHierarchy';
import useClientBanner from 'hooks/useClientBanner';
import useClientLogo from 'hooks/useClientLogo';
import { CreateBrief } from 'modules/CreateBrief';
import { useAtom } from 'jotai';
import { createBriefAtom } from 'store';
import { Summary } from 'modules/Dashboard/components/Summary';
import { getClientCurrency } from 'modules/helper';

/**
 * Client Workflow Hub - Dashboard
 */
const Briefs = props => {
  // props values :
  const { setHierarchyHandler, hierarchyData: clientStructure, clientCode, clientName } = props;
  // Mock data
  const briefListColumns = useMemo(() => mockColumns, []);

  // local state
  const initialFilterOptions = { H1: '', H2: '', agency: '' };
  const intitialfinalFilter = { H1: '', H2: '', agency: '' };
  const [filterOptions, setFilter] = useState(initialFilterOptions);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [finalFilter, setFinalFilter] = useState(intitialfinalFilter);
  const [, setCreateBriefWizard] = useAtom(createBriefAtom);
  const [ summeryFilter , setsSummeryFilter ] = useState({});


  //BRIEF LIST
  const {
    briefDataList,
    loading: listLoading,
    refetch,
    totalCount,
    briefPageSize,
    setBriefPageSize,
  } = useBriefList(JSON.parse(sessionStorage.getItem('hierarchy'))?.agency);

  const { banner } = useClientBanner();
  const { thumbnail } = useClientLogo();

  useEffect(() => {
    if (clientStructure) {
      const { H1, H2 } = clientStructure;
      let res;
      let res1;
      let resAgency;
      if (filterOptions?.H2 && finalFilter.H2) {
        res = filterOptions?.H2.find(filter => filter.value === finalFilter.H2);
      }
      if (filterOptions?.H1 && finalFilter.H1) {
        res1 = filterOptions?.H1.find(filter => filter.value === finalFilter.H1);
      }
      if (filterOptions?.agency && finalFilter.agency) {
        resAgency = filterOptions?.agency.find(filter => filter.value === finalFilter.agency);
      }
      let h1 = filterOptions.H1 && finalFilter.H1 ? res1.unrolledAccountIds : H1.unrolledAccountIds;
      let h2 = filterOptions.H2 && finalFilter.H2 ? res.unrolledAccountIds : H2.unrolledAccountIds;
      let updatedAgency = filterOptions.agency && finalFilter.agency ? resAgency.value : '*';
      setHierarchyHandler(h1, h2, updatedAgency);
      if (refetch) {
        refetch();
      }
    }
  }, [JSON.stringify(clientStructure), clientCode, JSON.stringify(finalFilter)]);

  // Client Structure Modal
  const clientStructureRef = useRef(null);
  const openClientStructure = (isOpen, data) => {
    clientStructureRef.current.isOpen(isOpen);
    clientStructureRef.current.data(data);
  };
  const clientStructureData = useMemo(
    () => ({
      clientName,
      content: 'content',
    }),
    [clientName],
  );

  const handleCloseCascader = () => setShouldRefetch(true);

  const geoProps = {
    value: finalFilter.H1,
    setHierarchy: value => setFilter({ ...filterOptions, H1: value }),
    handleSave: value => setFinalFilter({ ...finalFilter, H1: value }),
    handleSummery:value => setsSummeryFilter({H1:value}),
    handleClose: handleCloseCascader,
    type: 'location',
    label: 'Location',
    levels: ['Location'],
  };

  const brandProps = {
    value: finalFilter.H2,
    setHierarchy: value => setFilter({ ...filterOptions, H2: value }),
    handleSave: value => setFinalFilter({ ...finalFilter, H2: value }),
    handleClose: handleCloseCascader,
    type: 'brand',
    label: 'Brand',
    levels: ['Brand', 'Division', 'Business Group', 'Business Unit', 'Product Group', 'Product'],
  };

  const agencyProps = {
    value: finalFilter.agency,
    setHierarchy: value => setFilter({ ...filterOptions, agency: value }),
    handleSave: value => setFinalFilter({ ...finalFilter, agency: value }),
    handleClose: handleCloseCascader,
    type: 'agency',
    label: 'Agency',
    levels: ['Agency'],
  };

  // Markup to return
  return (
    <>
      <Helmet title="Client Workflow Hub" />
      <CreateBrief clientName={clientName} />
      <Page
        title={clientName}
        description="Stay on top of the briefs for your client by keeping an eye on who's doing what and when"
        banner={banner}
        thumbnail={thumbnail}
        primaryAction={{
          content: 'Create a Brief',
          onClick: () => setCreateBriefWizard(true),
          variant: 'secondary',
        }}
        secondaryActions={[
          {
            content: 'Manage Team',
            onClick: () => console.log('Item 1 clicked'),
            isDisabled: true,
          },
          {
            content: 'View Client Structure',
            onClick: () => openClientStructure(true, clientStructureData),
          },
        ]}
        controls={
          <>
            <Cascader {...agencyProps} />
            <Cascader {...brandProps} />
            <Cascader {...geoProps} />
          </>
        }
        
      >
        <Summary currency={getClientCurrency(clientStructure)} hasChart={finalFilter?.H1} clientStructure={clientStructure} finalFilter={summeryFilter} />
        <BriefList
          columns={briefListColumns}
          data={briefDataList}
          clientHierarchy={clientStructure}
          totalCount={totalCount}
          setBriefPageSize={setBriefPageSize}
          refetch={refetch}
          briefPageSize={briefPageSize}
          listLoading={listLoading}
          setHierarchyHandler={setHierarchyHandler}
        />

        <ClientStructureModal ref={clientStructureRef} clientName={clientName} />
      </Page>
    </>
  );
};

export default withClientHierarchy(Briefs)
