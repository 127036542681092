import gql from "graphql-tag";

export const FETCH_KPI = gql`
  query {
    getKPIs {
      id
      name
      code
      kpiType
      originalId
      sumCalculationType
    }
  }
`;

export const FETCH_CHANNEL = gql`
  query {
    getChannels {
      markets {
        name
        code
      }
      configurationStatus
      templateType
      groups {
        name
        code
        channels {
          code
          name
        }
      }
    }
  }
`;