export const statusVariances = {
    'Draft': 'default',
    'Finalising brief': 'default',
    'Ready to start': 'default',
    'In progress': 'neutral',
    'Completed': 'positive',
  };

  export const notificationData = [
    {
        icon:  "notifications",
    heading: "Client Portal",
    content: "Roopsi Narula from CAR has created...",
    timeAgo: "4 days ago",
    link: "View now",
    status:"info",
    divider: true
    },
    {
        icon:  "notifications",
    heading: "Cliet Management",
    content: "Roopsi Narula from CAR has created...",
    timeAgo: "4 days ago",
    link: "View now",
    status:"warning",
    divider: true
    },
    {
        icon:  "notifications",
    heading: "Plan Manager",
    content: "Roopsi Narula from CAR has created...",
    timeAgo: "4 days ago",
    link: "View now",
    status:"error",
    divider: false,
    }
]

export const fundingSourceOptions = [
    { label: 'Centrally funded', value: 'centralFunding' },
    { label: 'Locally funded', value: 'localFunding' },
  ];


export const downloadPdfButton = {
    showDownloadPdfButton: true,
    downLoadButtonLabel: 'Download brief as PDF',
    downloadButtonVariant: 'secondary',
  };

  export const chartChannelColor = [
    { channel: 'Video', color: '#6AA6EB' },
    { channel: 'Digital', color: '#8C5ED5' },
    { channel: 'Print', color: '#A32DC1' },
    { channel: 'Fees', color: '#00A1A5' },
    { channel: 'Radio', color: '#0660C8' },
    { channel: 'OOH', color: '#99E9EB' },
    { channel: 'Other', color: '#ECD5F2' },
  ];

  export const ModalLabel = {
    requestLabel : `Tell us why you're requesting changes to this deliverable so we can feedback to the team`,
    approveLabel : `Add a comment to give supporting information alongside your approval. (optional)`
  }