import React from 'react';
import { Avatar, Chip, FormatDateTime, Menu } from '@dentsu-ui/components';
import { DescriptionList } from 'components';
import { Styled } from './TaskCard.styles';
import { handleProfilePicture } from 'modules/helper';
import { useOwnerProfile } from 'hooks';
import config from '../../config';
// import { mediaEcosystemUrl } from '../../config';
import { clientCode, tenantId } from 'utils';

// CWH TaskCard component
function TaskCard({ isSubTask, task, ...rest }) {
  const { app: apps, internalDueDate, name, status, assignedTo } = task;
  const appCount = apps ? apps.length : 0;
  const primaryApp = apps.filter(app => app.isPrimary);
  const otherApps = apps.filter(app => !app.isPrimary);
  const meApps = apps?.filter(app => app.type === "meapp")
  const {profilePicture } = useOwnerProfile();
  const response = handleProfilePicture(assignedTo?.mail, profilePicture);

  const appName = (app) => {
    const appName = app.name;
      const transform = appName.toLowerCase();
      const hyphen = transform.replace(/ /g,"-");
      return hyphen;
  }

  // TODO: Replace with real data
  const statusVariances = {
    'In progress': 'neutral',
    'Not started': 'default',
    'Completed': 'positive',
  };

  // The markup to return
  return (
    <Styled.Wrapper {...rest}>
      <Styled.Body>
        <Styled.Column width="calc(calc(100% - 400px) / 1.75)">
          {name && (
            <Styled.Title isLarger={!isSubTask} isSemibold={!isSubTask} isSubTask={isSubTask}>
              {name}
            </Styled.Title>
          )}
        </Styled.Column>

        <Styled.Column width="200px">
          {status && (
            <Chip variant="status" status={statusVariances[status]} hasStatusLight>
              {status}
            </Chip>
          )}
        </Styled.Column>

        <Styled.Column flex="1">
          <DescriptionList>
            <Avatar style={{ margin: '0 10px' }} size="large" src={response?.data} name={assignedTo?.displayName} />
            {/* <DescriptionList.Term>Assigned to:</DescriptionList.Term> */}
            <DescriptionList.Detail>{assignedTo?.displayName || 'Unassigned'}</DescriptionList.Detail>
          </DescriptionList>
        </Styled.Column>

        <Styled.Column width="200px">
             {
             (appCount === 1 && meApps.length) ?
             meApps?.map(app => (
              <Styled.Link key={`app-${app._id}`} url={`${config.mediaEcosystemUrl}/${appName(app)}?clientCode=${clientCode}&tenantId=${tenantId}`} iconRight="share" isExternal>
                {app.name}
              </Styled.Link>
            )) :  
           appCount === 1 && apps?.map(app => (
             <Styled.Link key={`app-${app._id}`} url={app.url} iconRight="share" isDisabled={app.url === '' ? true : false} isExternal>
            {app.name}
          </Styled.Link>
            ))
          }
          {appCount > 1 && (
            <Menu>
              <Menu.Button icon="caret-down">{appCount} Apps available</Menu.Button>
              <Menu.List>
                {primaryApp && (
                  <Menu.Group title="Recommended app">
                    {primaryApp.map(app => (
                      <Menu.Item key={app._id} icon="share" onClick={() => {
                        window.open(app.url);
                      }}
                      isDisabled={app.url === '' ? true : false}
                      >
                        {app.name}
                      </Menu.Item>
                    ))}
                  </Menu.Group>
                )}
                {otherApps && (
                  <Menu.Group title="Other apps">
                    {otherApps.map(app => (
                      <Menu.Item key={app._id} icon="share" onClick={() => window.open(app.url)}>
                        {app.name}
                      </Menu.Item>
                    ))}
                  </Menu.Group>
                )}
              </Menu.List>
            </Menu>
          )}
        </Styled.Column>
      </Styled.Body>
    </Styled.Wrapper>
  );
}

export { TaskCard };
