export const statusOption = [
  {
    label: 'In review',
    value: 'inReview',
  },
  {
    label: 'Changes Requested',
    value: 'changeRequested',
  },
  {
    label: 'Approved',
    value: 'approved',
  },
];

export const tabListData = {
  0: 'Audience',
  1: 'Strategy',
  2: 'Plan',
  3: 'Activation',
  4: 'Measurement',
  5: 'Others',
};

export const TabsName = ['Strategy', 'Audience', 'Plan', 'Activation', 'Measurement', 'Others'];

export const tabListData1 = {
  Audience: 0,
  Strategy: 1,
  Plan: 2,
  Activation: 3,
  Measurement: 4,
  Others: 5,
};

export const setColorVariant = status => {
  const statusCode = {
    inReview: 'neutral',
    changeRequested: 'warning',
    approved: 'positive',
  };
  return statusCode[status] || 'default';
};

export const statusTransform = {
  changeRequested: 'changeRequested',
  approved: 'approved',
  inReview: 'inReview',
};

export const mockColumns = [
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
  },
  {
    Header: 'show',
    accessor: 'show',
    id: 'clientApprovalStatus',
    allowFilter: true,
    options: statusOption,
  },
];
