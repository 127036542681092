import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_BRIEF_LOGO } from 'modules/MediaBrowser/graphql';
import Loader from 'components/Loader';

const useAllBriefLogo = () => {

  const [briefLogo, setBriefLogo] = useState([]);


    const handleAllimage = image => {
        const getAllLogo = image && image.getAllBriefLogo.data;
        if (getAllLogo && getAllLogo.length) {
          setBriefLogo(getAllLogo);
        }
      };
    
      const { loading: imageLoading, refetch : refetchLogo } = useQuery(GET_ALL_BRIEF_LOGO, {
        variables: {
          active: true,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: image => handleAllimage(image),
      });
    
      if (imageLoading) return <Loader />;

      return { briefLogo , refetchLogo };

}

export default useAllBriefLogo;