import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Loading } from '@dentsu-ui/components';

import { FETCH_CLIENT_STRUCTURE, FETCH_AGENCY } from '../modules/Dashboard/graphql/query';
import { handleClientStructure, getLevelName, getFilteredAgency } from './helper';

const useClientHierarchy = ({ children }) => {
  const [clientStructure, setClientStructure] = useState({
    H1: {},
    H2: {},
    brandLevels: [],
    geoLevels: [],
    clientName: '',
    agencyLevel: [],
    agency: {},
    clientCode: '',
  });
  const { data, loading } = useQuery(FETCH_CLIENT_STRUCTURE, {
    skip: !!(clientStructure.brandLevels.length && clientStructure.geoLevels.length),
  });
  const { data: agencyResponse, loading: load } = useQuery(FETCH_AGENCY);

  let agency = {};
  let getClientStructure = {};

  if (data && agencyResponse) {
    const keysToUpdate = {
      name: 'label',
      // unrolledAccountIds: 'value',
      accountId: 'value',
      sub: 'children',
    };
    const {
      getCLPClientStructure: { data: response },
    } = data;
    const { getAgencies } = agencyResponse;
    agency = getFilteredAgency(getAgencies, response.H1.levelName, response.H1.name);

    const structuredData = handleClientStructure(response, keysToUpdate);
    const { H1 = {}, H2 = {} } = structuredData || {};
    const brandLevels = getLevelName(H2);
    const geoLevels = getLevelName(H1);
    const agencyLevel = ['Agency'];
    const clientCode = response.clientCode;
    const clientName = response.clientName;
    getClientStructure = { H1, H2, brandLevels, geoLevels, agencyLevel, agency, clientCode, clientName };
  }

  useEffect(() => {
    if (getClientStructure && Object.keys(getClientStructure).length) {
      setClientStructure(getClientStructure);
    }
  }, [getClientStructure]);

  if (loading || load) return <Loading />;
  const clientName = clientStructure.clientName;
  const clientCode = clientStructure.clientCode;

  return { clientStructure, clientName, clientCode };
};

export default useClientHierarchy;
