import { Subheading, Stack, Box, Paragraph } from '@dentsu-ui/components';

export const Lead = ({ leaadHeading, leadAgency, leadAgencyData, supportingHeading, supportingAgency, supportingAgencyData }) => {
  return (
    <>
      <Box mb="10px">
        <Subheading isSmaller isLight>
          {leaadHeading}
        </Subheading>
      </Box>
      <Stack mb="2rem">
        <Box width={[1 / 2]} pr={5}>
          {leadAgency}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{leadAgencyData}</Paragraph>
        </Box>
      </Stack>
      <Box mb="10px">
        <Subheading isSmaller isLight>
          {supportingHeading}
        </Subheading>
      </Box>
      <Stack mb="2rem">
        <Box width={[1 / 2]} pr={5}>
          {supportingAgency}
        </Box>
        <Box width={[1 / 2]}>
          {supportingAgencyData?.map(({ name, index }) => {
            return (
              <Paragraph isSemibold key={index}>
                {name}
              </Paragraph>
            );
          })}
        </Box>
      </Stack>
    </>
  );
};
