import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Chip,
  Circle,
  Image,
  Divider,
  EmptyState,
  FormatCurrency,
  FormatDateTime,
  Icon,
  Link,
  List2,
  Paragraph,
  Subheading,
  Toast,
} from '@dentsu-ui/components';
import { BriefCard, DescriptionList } from 'components';
import { getBrandLocation, handleProfilePicture } from 'modules/helper';
import { statusVariances } from 'constants';
import useOwnerProfile from 'hooks/useOwnerProfile';
import { MediaBrowser } from 'modules/MediaBrowser';
import { useAtom } from 'jotai';
import { clientLogoAtom } from 'store';

/*
 * Client Portal - Brief List
 */

export function BriefList({
  columns,
  data,
  planData,
  listLoading,
  refetch,
  setBriefPageSize,
  clientHierarchy,
  briefPageSize,
  clientName,
  totalCount,
}) {
  const [filterValue, setFilterValue] = useState([]);
  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [defaultPageLimit, setDefaultPageLimit] = useState(10);
  const [showMediaBrowser, setShowMediaBrowser] = useState(false);
  const [activeBrief, setActiveBrief] = useState({});
  const [defaultLogo] = useAtom(clientLogoAtom);
  const statusId = 'status';

  const toast = Toast();

  // FETCH_TEAMS_PROFILE
  const { profilePicture } = useOwnerProfile();

  // PLANNED STATUS APPROVAL
  const handlePlanData = (originalId, planData) => {
    const planedArr = [];
    const unplannedArr = [
      "You don't have any available planning data yet. Once your team gets started on a plan, you'll see planning status here",
    ];

    if (originalId && planData && planData.length) {
      planData.map(plan => {
        if (plan.briefId === originalId) {
          const { approvedCount, totalCount } = plan;
          planedArr.push(`${approvedCount} out of ${totalCount} plan is approved`);
        }
      });
    }
    return planedArr && planedArr.length ? planedArr[0] : unplannedArr[0];
  };

  const handlePublishNow = async () => {
    setShowMediaBrowser(!showMediaBrowser);
    toast({
      content: 'Brief image has updated',
      position: 'top-right',
      status: 'success',
    });
  };

  // FILTER FOR THE STATUS OF THE BRIEF AND PAGINATION
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setDefaultPageLimit(pageSize);
      setBriefPageSize(pageSize);
      setPageNumber(pageIndex + 1);
      setPageCount(Math.ceil(totalCount / pageSize));
      setTotal(pageSize);
    },
    [search, filterValue, totalCount],
  );

  useEffect(() => {
    setSearchLoading(true);
    const delayDebounceFn = setTimeout(() => {
      refetch({ search: { name: search, status: filterValue[0]?.value }, page: pageNumber });
      setSearchLoading(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filterValue, pageNumber, briefPageSize]);

  const handleFilterChange = filterData => {
    if (filterData && filterData.length) {
      setFilterValue(filterData);
      let searchValue = { name: search };
      filterData.map(item => {
        const { id, value } = item;
        if (id === statusId) {
          return (searchValue.status = value);
        }
      });
    }
    if (!filterData.length) {
      setFilterValue('');
    }
  };
  const handleMediaBrowser = brief => {
    setShowMediaBrowser(true);
    setActiveBrief(brief);
  };

  return (
    <>
      <List2
        defaultMode="grid"
        gridColumns={4}
        columns={columns}
        data={data}
        hasPagination={true}
        hasTotal={true}
        isLoading={listLoading || searchLoading}
        isFilterable={true}
        search={search}
        filters={filterValue || []}
        total={totalCount}
        onFiltersChange={handleFilterChange}
        isSearchable={true}
        onSearchChange={setSearch}
        isManualPagination
        fetchData={fetchData}
        pageIndex={pageIndex}
        onPageIndexChange={setPageIndex}
        pageCount={pageCount}
        defaultPageSize={defaultPageLimit}
        rowText="Brief"
        rowsText="Briefs"
        emptyState={
          <EmptyState layout="horizontal" size="small" image="create">
            <Paragraph>Uh oh, looks like you haven't create a data source yet</Paragraph>
          </EmptyState>
        }
        renderItem={(item, index) => {
          const {
            budgetAmount,
            budgetCurrency,
            createdAt,
            creatorName,
            endDate,
            briefTaskCount,
            name,
            h2,
            originalId,
            ownerEmail,
            ownerName,
            startDate,
            status,
            logoUrl,
          } = item;
          const hasWarning = index === 0;
          const brand = getBrandLocation(h2, clientHierarchy?.H2);
          const response = handleProfilePicture(ownerEmail, profilePicture);

          return (
            // <List2.Row
            //   key={originalId}
            //   title={name}
            //   metadata={''}
            //   media={
            //     <Image
            //       htmlWidth={40}
            //       htmlHeight={40}
            //       src={response ? response.data : ''}
            //       fallbackSrc={logoUrl || 'https://via.placeholder.com/50'}
            //     />
            //   }
            //   actions={
            //     <Link
            //       style={{ whiteSpace: 'nowrap' }}
            //       iconRight="chevron-right"
            //       onClick={() => handleMediaBrowser(item)}
            //     >
            //       Change image
            //     </Link>
            //   }
            // ></List2.Row>

            <List2.Card
              key={originalId}
              variant="functional"
              // media={{
              //   src: response ? response.data : '',
              //   aspectRatio: '16:9',
              //   fallbackSrc: logoUrl || 'https://via.placeholder.com/50',
              //   name: `${name}`,
              // }}
            >
              <List2.Card.Status hasStatusLight status={statusVariances[status]}>
                {status}
              </List2.Card.Status>
              <List2.Card.Thumbnail>
                <Image
                  htmlWidth={60}
                  height={60}
                  objectFit="contain"
                  isRounded={true}
                  src={logoUrl ? logoUrl : ''}
                  fallbackSrc={defaultLogo || `/images/default_logo.png`}
                  alt="This is alt text"
                />
              </List2.Card.Thumbnail>
              <List2.Card.Metadata>{brand}</List2.Card.Metadata>
              <List2.Card.Title>{name}</List2.Card.Title>
              <List2.Card.Actions>
                <Link iconLeft="layers" onClick={() => handleMediaBrowser(item)}>
                  Update image
                </Link>
              </List2.Card.Actions>
            </List2.Card>

            // <BriefCard key={originalId}>
            //   <BriefCard.Header
            //     subtitle={brand}
            //     title={name}
            //     owner={ownerName}
            //     uploadImage={logoUrl || ''}
            //     thumbnail={response ? response.data : ''}
            //   />
            //   <BriefCard.Body>
            //     <BriefCard.Body.ColOne>
            //       <DescriptionList>
            //         <DescriptionList.Term>Duration:</DescriptionList.Term>
            //         <DescriptionList.Detail>
            //           {startDate && <FormatDateTime isUnstyled mode="local" format="date" value={startDate} />}
            //           {' — '}
            //           {endDate && <FormatDateTime isUnstyled mode="local" format="date" value={endDate} />}
            //         </DescriptionList.Detail>
            //         <DescriptionList.Term>Budget:</DescriptionList.Term>
            //         <DescriptionList.Detail>
            //           <FormatCurrency isUnstyled value={budgetAmount} currency={budgetCurrency} />
            //         </DescriptionList.Detail>
            //         <DescriptionList.Term>Created by:</DescriptionList.Term>
            //         <DescriptionList.Detail>
            //           {creatorName}
            //           {', '}
            //           {createdAt && <FormatDateTime isUnstyled mode="local" format="date" value={createdAt} />}
            //         </DescriptionList.Detail>
            //       </DescriptionList>
            //     </BriefCard.Body.ColOne>

            //     <BriefCard.Body.ColTwo>
            //       {status && (
            //         <Chip variant="status" hasStatusLight status={statusVariances[status]}>
            //           {status}
            //         </Chip>
            //       )}
            //     </BriefCard.Body.ColTwo>

            //     <BriefCard.Body.ColThree>
            //       <Button iconRight="upload" variant="ghost" onClick={() => handleMediaBrowser(item)}>
            //         upload Image
            //       </Button>
            //     </BriefCard.Body.ColThree>
            //   </BriefCard.Body>

            //   <BriefCard.Footer>
            //     <DescriptionList isInline hasSeparator>
            //       <DescriptionList.Term>Planning status:</DescriptionList.Term>
            //       <DescriptionList.Detail>{handlePlanData(originalId, planData)}</DescriptionList.Detail>
            //       {hasWarning && (
            //         <>
            //           <DescriptionList.Term>
            //             <Icon color="var(--dentsu-color-a500)" icon="warning-sign" size={14} /> Action required:
            //           </DescriptionList.Term>
            //           <DescriptionList.Detail isBold>{briefTaskCount} Review brief from agency</DescriptionList.Detail>
            //         </>
            //       )}
            //     </DescriptionList>
            //   </BriefCard.Footer>
            // </BriefCard>
          );
        }}
      />
      <MediaBrowser
        showMediaBrowser={showMediaBrowser}
        setShowMediaBrowser={setShowMediaBrowser}
        activeBrief={activeBrief}
        handlePublishNow={handlePublishNow}
        refetch={refetch}
      />
    </>
  );
}
