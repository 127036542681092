export const mockColumns = [
  {
    Header: 'Owner',
    accessor: 'owner',
    id: 'owner',
  },
  {
    Header: 'Client code',
    accessor: 'clientCode',
    id: 'clientCode',
  },
  {
    Header: 'Name',
    accessor: 'name',
    id: 'name',
  },
  {
    Header: 'Status',
    accessor: 'status',
    id: 'status',
    allowFilter: true,
    options: [
      {
        label: 'Draft',
        value: '0',
      },
      {
        label: 'Finalising brief',
        value: '1',
      },
      {
        label: 'Ready to start',
        value: '2',
      },
      {
        label: 'In progress',
        value: '3',
      },
      {
        label: 'Completed',
        value: '4',
      },
    ],
  },
];


