import {
  Accordion,
  Box,
  Button,
  Chip,
  Divider,
  Heading,
  List,
  Paragraph,
  Stack,
  Takeover,
} from '@dentsu-ui/components';
import { statusVariances } from 'constants';
import { ViewBriefWrapper, ViewBriefPage, AccordionContainer } from './ViewBrief.style';
import moment from 'moment';
import BasicDetails from './components/BasicDetails';
import ObjectiveBrief from './components/ObjectiveBrief';
import AudienceBrief from './components/AudienceBrief';
import { useState } from 'react';
import {
  downloadAttachmentList,
  formatAttachmentName,
} from 'modules/helper';
import { getFormattedDate } from 'modules/Briefs/components/helper';
import { useEffect } from 'react';
import { useRef } from 'react';

export function BriefViewDetail({ isTakeOverOpen, setIsTakeOverOpen, brand, briefData, marketName }) {
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentDownloadDetail, setAttachmentDownloadDetail] = useState({
    attachmentDownloadLoading: true,
    attachmentIdForDownload: '',
  });

  const campaignBudgetRef = useRef();
  const approvedTime = moment(new Date(briefData.brief.approvedDate)).format('LT');
  const approvedDate = moment(new Date(briefData.brief.approvedDate)).format('LL');
  const briefAttachments = briefData?.briefDocuments?.map(document => ({
    fileName: document.documentName,
    uploaded: document.createdAt,
    documentId: document.id,
    briefId: briefData?.brief?.id,
  }));

  useEffect(() => {
    if (briefAttachments?.length && !attachmentList?.length) {
      const list =
        briefAttachments &&
        briefAttachments.map(item => ({
          ...item,
          loading: false,
        }));
      setAttachmentList([...list]);
      return;
    }

    if (attachmentList?.length) {
      const { attachmentDownloadLoading, attachmentIdForDownload } = attachmentDownloadDetail;
      const list =
        attachmentList &&
        attachmentList.map(item => ({
          ...item,
          loading: item.documentId === attachmentIdForDownload ? attachmentDownloadLoading : item.loading,
        }));
      return setAttachmentList([...list]);
    }
  }, [attachmentDownloadDetail]);

  const {
    name,
    briefReference,
    status,
    version,
    approverName,
    marketingObjective,
    briefObjective,
    businessObjective,
    comments,
    geography,
  } = briefData?.brief;

  return (
    <>
      <Takeover isOpen={isTakeOverOpen} isFullWidth>
        <Takeover.Header
          metadata={`${brand} | ${name}`}
          title={{
            label: 'View Brief',
          }}
        >
          <Button variant="tertiary" onClick={() => setIsTakeOverOpen(false)}>
            Close
          </Button>
        </Takeover.Header>
        <Takeover.Body>
          <ViewBriefWrapper>
            <ViewBriefPage>
              <Box mb="30px">
                <Box mb="4px">
                  <Paragraph isSecondary>{`BRIEF ID : [${briefReference}]`}</Paragraph>
                </Box>
                <Stack alignItems="center" mb="3px">
                  <Heading as="h1">{name}</Heading>
                  <Box ml="8px" width="18%">
                    <Chip variant="status" hasStatusLight status={statusVariances[status]}>
                      {status}
                    </Chip>
                  </Box>
                </Stack>
                <Box display="flex">
                  <Paragraph isSecondary>Version: {version || 0}</Paragraph>
                  <Divider isFlexChild orientation="vertical" />
                  <Paragraph isSecondary>
                    {`Updated and approved by ${approverName} on behalf of  at ${approvedTime} on ${approvedDate}`}
                  </Paragraph>
                </Box>
              </Box>
              <AccordionContainer>
                <Accordion defaultOpenItems={[0, 1, 2, 3]} className="AccordionStyle1">
                  <Accordion.Item title={'The basics'}>
                    <BasicDetails briefData={briefData?.brief} brand={brand} marketName={marketName} campaignBudgetRef={campaignBudgetRef} />
                  </Accordion.Item>
                  <Accordion.Item title={'Objectives, KPIs & channels'}>
                    <ObjectiveBrief
                      overallKpi={briefData?.briefOverallKPIs}
                      channelKpi={briefData?.channelKPIs}
                      excludeChannel={briefData?.excludeChannels}
                      marketingObjective={marketingObjective}
                      briefObjective={briefObjective}
                      businessObjective={businessObjective}
                    />
                  </Accordion.Item>
                  <Accordion.Item title={'Audience & geography'}>
                    <AudienceBrief
                      briefAudience={briefData?.briefAudience}
                      comments={comments}
                      geographyData={geography}
                    />
                  </Accordion.Item>
                  <Accordion.Item title={'Attachments'}>
                    <List
                      items={attachmentList}
                      emptyState={'-'}
                      hasTotal={false}
                      renderItem={(item, index) => {
                        const { fileName, uploaded, documentId, briefId, loading } = item;
                        const formatedDate = getFormattedDate(uploaded);
                        const formattedFileName = formatAttachmentName(fileName);
                        return (
                          <List.Row
                            key={index}
                            title={formattedFileName}
                            metadata={`Uploaded: ${formatedDate}`}
                            actions={
                              <Button
                                variant="ghost"
                                size="small"
                                isLoading={loading}
                                onClick={() =>
                                  downloadAttachmentList(
                                    documentId,
                                    briefId,
                                    formattedFileName,
                                    setAttachmentDownloadDetail,
                                  )
                                }
                              >
                                {'Download'}
                              </Button>
                            }
                          ></List.Row>
                        );
                      }}
                    />
                  </Accordion.Item>
                </Accordion>
              </AccordionContainer>
            </ViewBriefPage>
          </ViewBriefWrapper>
        </Takeover.Body>
        <Takeover.Footer>
          <Stack width="100%" >
            <Button variant="tertiary" onClick={() => setIsTakeOverOpen(false)}>
              Back to WorkFlow
            </Button>
          </Stack>
        </Takeover.Footer>
      </Takeover>
    </>
  );
}
