import React, { useEffect, useState } from 'react';

import {
  Button,
  Box,
  Stack,
  Takeover,
  TextContainer,
  FileLockup,
  Paragraph,
  Spacer,
  Subheading,
  Caption,
  Divider,
  SummaryBar,
  Chip,
  Avatar,
  Toast,
  IconButton,
  Modal,
} from '@dentsu-ui/components';
import { getFormatDate, fetchAttachment } from 'modules/helper';
import { setColorVariant, statusTransform } from 'modules/CLPBriefDetails/constant';
import { useAuth } from 'contexts/authContext';
import Loader from 'components/Loader';
import PopupModal from 'components/PopupModal';
import { ModalLabel } from 'constants';

const DeliverableDetails = ({
  setShowDeliverable,
  showDeliverable,
  loading,
  briefName,
  deliverableType,
  deliverableList,
}) => {
  const [attachmentList, setAttachmentList] = useState([]);
  const [attachmentLoadingDetail, setAttachmentLoadingDetail] = useState({
    attachmentDownloadLoading: true,
    attachmentIdForDownload: '',
  });
  const [userData, setUserData] = useState({});
  const [isApprove, setIsApprove] = useState(false);
  const [requestChange, setRequestChange] = useState(false);

  const { auth: { token: { getUserInfo = () => {} } = {} } = {} } = useAuth() || {};

  const { reviews, attachments, clientApprovalStatus, name, majorVersion, minorVersion, description } = deliverableList;

  const { reviewedAt, reviewDate, feedback, reviewer, sharedAt } = (reviews && reviews[0]) || [];
  const reviewerName = reviewer?.displayName;
  const { inReview, approved, changeRequested } = statusTransform;

  useEffect(() => {
    async function getUserData() {
      const user = await getUserInfo();
      const { email = '', name = '' } = user || {};
      if (!Object.keys(userData).length) {
        setUserData({ userName: name, userEmail: email });
      }
    }
    if (!Object.keys(userData).length) {
      getUserData();
    }
  }, [getUserInfo, userData]);

  useEffect(() => {
    if (!attachmentList.length && attachments.length) {
      const list =
        attachments &&
        attachments.map(item => ({
          ...item,
          loading: false,
        }));
      setAttachmentList([...list]);
      return;
    }

    if (attachmentList.length) {
      const { attachmentDownloadLoading, attachmentIdForDownload } = attachmentLoadingDetail;
      const temp = attachmentList.map(item => ({
        ...item,
        loading: item.originalId === attachmentIdForDownload ? attachmentDownloadLoading : item.loading,
      }));
      return setAttachmentList([...temp]);
    }
  }, [attachments, attachmentLoadingDetail]);

  const handleAttachDocument = async (documentId, documentName) => {
    fetchAttachment(documentId, documentName, setAttachmentLoadingDetail);
  };

  if (loading) return <Loader />;

  return (
    <>
      <Takeover isOpen={showDeliverable}>
        <Takeover.Header metadata={`${briefName} | ${deliverableType}`} title={{ label: 'View deliverable' }}>
          <Button variant="tertiary" onClick={() => setShowDeliverable(false)}>
            {'close'}
          </Button>
        </Takeover.Header>
        <Takeover.Body>
          <Box mt={'0.5rem'}>
            {clientApprovalStatus === inReview && (
              <SummaryBar>
                {'Pending review by'}
                <Spacer width={4} />
                {reviewerName}
                <Spacer width={4} />
                {'by'}
                <Spacer width={4} />
                {getFormatDate(reviewDate)}
              </SummaryBar>
            )}
            <TextContainer>
              <Box display="flex" justifyContent="space-between">
                <Subheading isSmaller>{name}</Subheading>
                <Chip variant="status" status={setColorVariant(clientApprovalStatus)} hasStatusLight>{`${[
                  statusTransform[clientApprovalStatus],
                ]}`}</Chip>
              </Box>
              <Box display="flex" mt={-15} alignItems="flex-end">
                <Caption isSecondary>
                  {'Version'} {`${majorVersion}.${minorVersion}`}
                </Caption>
                <Divider orientation="vertical" isFlexChild />
                <Caption isSecondary>
                  {'Created By'}
                  <Spacer width={4} />
                  {reviewerName}
                  <Spacer width={4} />
                  {getFormatDate(sharedAt)}
                </Caption>
              </Box>
              {reviewer && (
                <>
                  <Box display="flex" mt={24} mb="4px">
                    <Caption isSecondary>
                      {clientApprovalStatus === inReview
                        ? 'Review requested by'
                        : clientApprovalStatus === changeRequested
                        ? 'Changes suggested by'
                        : 'Approved by'}
                    </Caption>
                    <Spacer width={4} />
                    {clientApprovalStatus === inReview ? (
                      <Avatar size="medium" name={reviewerName} src={'srcImgData'}>
                        {reviewerName}
                      </Avatar>
                    ) : (
                      <Caption>{reviewerName}</Caption>
                    )}
                    {clientApprovalStatus !== inReview && (
                      <Caption>
                        <Spacer width={4} />
                        {'on'}
                        <Spacer width={4} />
                        {getFormatDate(reviewedAt)}
                      </Caption>
                    )}
                  </Box>
                  {[changeRequested, approved].includes(clientApprovalStatus) && feedback && (
                    <Paragraph>{`“${feedback}”`}</Paragraph>
                  )}
                  {clientApprovalStatus === inReview && reviewDate && (
                    <>
                      {description && <Paragraph>"{description}"</Paragraph>}
                      <Box display="flex" mt={24} mb={24}>
                        <Paragraph isSecondary>{'Review By'}</Paragraph>
                        <Spacer width={8} />
                        <Paragraph isSemibold>{getFormatDate(reviewDate)}</Paragraph>
                      </Box>
                    </>
                  )}
                </>
              )}
            </TextContainer>
            <TextContainer>
              <Box mt={24}>
                {attachmentList &&
                  attachmentList.map((file, key) => (
                    <FileLockup
                      id={key}
                      key="key"
                      name={file.fileName}
                      extension={file.fileExtension || ''}
                      filesize={parseInt(file.fileSize) || ''}
                      hasStatus
                      statusLabel={`Version ${file.version}` || ''}
                      action={
                        <IconButton
                          id="file-lock-up"
                          variant="ghost"
                          icon="download"
                          isLoading={file.loading}
                          onClick={() => handleAttachDocument(file.originalId, file.fileName)}
                        />
                      }
                    />
                  ))}
              </Box>
            </TextContainer>
          </Box>
        </Takeover.Body>
        {clientApprovalStatus !== 'approved' && (
          <Takeover.Footer>
            <Stack justifyContent="flex-end">
              <Box>
                <Button
                  variant="secondary"
                  isDisabled={clientApprovalStatus !== approved && userData?.userName !== reviewerName}
                  onClick={() => setRequestChange(true)}
                >
                  {'Request Changes'}
                </Button>
                <Button
                  id="approve"
                  variant="primary"
                  isDisabled={clientApprovalStatus !== approved && userData?.userName !== reviewerName}
                  onClick={() => setIsApprove(true)}
                >
                  {'Approve'}
                </Button>
              </Box>
            </Stack>
          </Takeover.Footer>
        )}
      </Takeover>
      <PopupModal
        isApprove={isApprove}
        setIsApprove={setIsApprove}
        isRequest={requestChange}
        setRequestChange={setRequestChange}
        buttonName={requestChange ? 'Request Changes' : 'approve'}
        name={name}
        label={requestChange ? ModalLabel.requestLabel : ModalLabel.approveLabel}
        title={requestChange ? 'You are requesting changes to' : 'You are approving'}
      />
    </>
  );
};

export default DeliverableDetails;
