import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_LOGO } from 'modules/Dashboard/graphql';
import { getClientLogoBanner } from 'modules/helper';
import { useAtom } from 'jotai';
import { clientLogoAtom } from 'store';
// GQL

const useClientLogo = () => {
  const [clientLogo, setClientLogo] = useState('');
  const [thumbnail, setThumbnail] = useState('');
  const [, setLogo] = useAtom(clientLogoAtom);

  const handleClientLogo = logo => {
    setClientLogo((logo && logo.getCLPClientLogo.logoImageUrl) || '');
  };
  useQuery(GET_CLIENT_LOGO, {
    fetchPolicy: 'network-only',
    onCompleted: logo => handleClientLogo(logo),
  });

  useEffect(() => {
    const clientLogofun = async () => {
      if (clientLogo && clientLogo?.length) {
        const clientLogoImage = await getClientLogoBanner(clientLogo);
        setThumbnail(clientLogoImage);
        setLogo(clientLogoImage);
      }
    };
    clientLogofun();
  }, [clientLogo]);

  return { thumbnail };
};

export default useClientLogo;
