import { Subheading, Stack, Box, Paragraph } from '@dentsu-ui/components';
export const About = ({ aboutHeading, type, typeData, agencyType, agencyTypeData, sector, sectorData, country, countryData }) => {
  return (
    <>
      <Box mb="10px">
        <Subheading isSmaller isLight>
          {aboutHeading}
        </Subheading>
      </Box>
      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {type}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{typeData}</Paragraph>
        </Box>
      </Stack>
      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {agencyType}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{agencyTypeData}</Paragraph>
        </Box>
      </Stack>
      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {sector}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{sectorData}</Paragraph>
        </Box>
      </Stack>
      <Stack mb="2rem">
        <Box width={[1 / 2]} pr={5}>
          {country}
        </Box>
        <Box width={[1 / 2]}>
          {countryData.map(({ name, index }) => {
            return (
              <Paragraph isSemibold key={index}>
                {name}
              </Paragraph>
            );
          })}
        </Box>
      </Stack>
    </>
  );
};
