import { Route, Redirect } from 'react-router-dom';
import loadable from '@loadable/component';
import { AppWrapper, AppHeader, AppMain, AppFooter, Shell } from 'components';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Provider } from 'jotai';
import { SwitchClient } from 'modules';
import { CLPBriefDetails } from 'modules/CLPBriefDetails/CLPBriefDetails';

// Client portal
const Dashboard = loadable(() => import('./modules'), {
  resolveComponent: components => components.Dashboard,
});

// // Client Portal detail

// const CLPBriefDetails = loadable(() => import('./modules'), {
//   resolveComponent: components => components.CLPBriefDetails,
// });

// Client workflow hub
const Briefs = loadable(() => import('./modules'), {
  resolveComponent: components => components.Briefs,
});
const BriefDetail = loadable(() => import('./modules'), {
  resolveComponent: components => components.BriefDetail,
});
const Settings = loadable(() => import('./modules'), {
  resolveComponent: components => components.Settings,
});

const ProtectedRoute = () => {
  return (
    <Provider>
      <AppWrapper>
        <AppHeader />
        <AppMain>
          <Shell>
            <ErrorBoundary>
              <Route exact path="/">
                <Redirect to="/client-portal" />
              </Route>
              <Route exact path="/briefs">
                <Redirect to="/client-workflow-hub" />
              </Route>
              <Route exact path="/client-portal">
                <Dashboard />
              </Route>
              <Route path="/client-portal/:id">
                <CLPBriefDetails />
              </Route>
              <Route exact path="/client-workflow-hub">
                <Briefs />
              </Route>
              <Route path="/client-workflow-hub/:id">
                <BriefDetail />
              </Route>
              <Route path="/settings">
                <Settings />
              </Route>
            </ErrorBoundary>
          </Shell>
        </AppMain>
        <AppFooter />
        <SwitchClient />
      </AppWrapper>
    </Provider>
  );
};

export default ProtectedRoute;
