import { useQuery } from '@apollo/client';
// GQL
import {FETCH_KPI } from 'modules/BriefViewDetail/graphql';


const useKpiData = () => {

  const { data: { getKPIs: kpiData = [] } = {} } = useQuery(FETCH_KPI);
  return {kpiData};
};

export default useKpiData;
