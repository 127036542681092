import styled from 'styled-components';
import { Caption, Paragraph, Stack } from '@dentsu-ui/components';

const Wrapper = styled.div`
  --header-width: max(35%, 300px);
  --footer-width: 100%;

  background: var(--dentsu-color-white);
  color: var(--dentsu-color-n1000);
  font-size: var(--dentsu-font-size-14);
  border: 1px solid var(--dentsu-color-n250);
  border-top-color: var(--dentsu-color-n350);
  display: flex;
  flex-wrap: wrap;
  margin-block: var(--dentsu-spacing-12);
  padding: var(--dentsu-spacing-24);
  pointer-events: ${props => (props.isDisabled ? 'none' : 'auto')};
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'auto')};
  opacity: ${props => (props.isDisabled ? 0.4 : 1)};
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  width: var(--header-width);
  padding-inline-end: var(--dentsu-spacing-24);
`;

const Thumbnail = styled.div`
  margin-inline-end: var(--dentsu-spacing-16);
  margin-block-end: var(--dentsu-spacing-16);


`;

const Subtitle = styled(Caption).attrs({
  isSecondary: true,
})`
  margin-block-end: var(--dentsu-spacing-2);
`;

const Title = styled(Paragraph).attrs({
  isBold: true,
})`
  font-size: 16px;
`;

const Owner = styled('div')`
  display: flex;
  align-items: center;
  margin: 4px 0 0;
  & > span {
    margin-right: 0.5ch;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Body = styled(Stack)`
  flex: 1;
  align-items: center;
`;

const ColumnOne = styled(Stack).attrs({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})`
  width: 56%;
  gap: var(--dentsu-spacing-8);
`;

const ColumnTwo = styled(Stack).attrs({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
})`
  width: 22%;
  gap: var(--dentsu-spacing-8);

  .dentsu-chip {
    margin-block-start: 0;
    margin-inline-start: 0;
  }
`;

const ColumnThree = styled(Stack).attrs({
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
})`
  width: 22%;
  gap: var(--dentsu-spacing-8);
`;

const Footer = styled.footer`
  width: var(--footer-width);
`;

export const Styled = {
  Body,
  ColumnOne,
  ColumnThree,
  ColumnTwo,
  Footer,
  Header,
  Owner,
  Subtitle,
  Thumbnail,
  Title,
  Wrapper,
};
