import styled from 'styled-components';

// import { ColorWhiteBase } from '@dentsu-ui/elements';
import { Page } from '@dentsu-ui/components';

// import media from '../helper/media';

export const ViewBriefWrapper = styled.div`
  display: block;
  min-height: 100%;
  /* overflow: auto; */
  position: relative;
  width: 95%;
`;

export const ViewBriefPage = styled(Page)`
  position: absolute;
  width: 96%;

  .dentsu-page__description {
    font-style: normal;
    max-width: 70ch;
    margin-top: 0;
  }

  .dentsu-modal {
    width: 750px;
  }

  .sidebar {
    position: sticky !important;
    align-self: flex-start;
    top: 1rem;
  }
  .Pagestyles__StyledPageHeader-snr1bl-3 {
    margin-bottom: 0px;
  }
`;

export const AccordionContainer = styled.div`
  .AccordionStyle {
    margin-bottom: 40px;
  }
  .AccordionStyle .ifpFwo {
    border-style: hidden;
  }

  .AccordionStyle li {
    border-bottom: 1px solid #f0f0f1;
  }
`;