import React, { useCallback, useEffect, useState } from 'react';
import { Chip, EmptyState, FormatNumber, FormatDateTime, Link, List2, Paragraph } from '@dentsu-ui/components';
import { BriefCard, DescriptionList } from 'components';
import { getBrandLocation, handleProfilePicture } from 'modules/helper';
import { statusVariances } from 'constants';
import useOwnerProfile from 'hooks/useOwnerProfile';
import { Link as RouterLink } from 'react-router-dom';

/*
 * Client Workflow Hub - Brief List
 */
export function BriefList({
  columns,
  data,
  clientHierarchy,
  totalCount,
  refetch,
  setBriefPageSize,
  briefPageSize,
  listLoading,
  defaultBriefImage,
  setHierarchyHandler,
}) {
  //pagination and status filter
  const [filterValue, setFilterValue] = useState([]);
  const [search, setSearch] = useState('');
  const [pageIndex, setPageIndex] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [defaultPageLimit, setDefaultPageLimit] = useState(10);
  const statusId = 'status';

  // FETCH_PROFILE
  const { profilePicture } = useOwnerProfile();

  // FILTER FOR THE STATUS OF THE BRIEF AND PAGINATION
  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setDefaultPageLimit(pageSize);
      setBriefPageSize(pageSize);
      setPageNumber(pageIndex + 1);
      setPageCount(Math.ceil(totalCount / pageSize));
      setTotal(pageSize);
    },
    [search, filterValue, totalCount],
  );

  useEffect(() => {
    setSearchLoading(true);
    const delayDebounceFn = setTimeout(() => {
      refetch({ search: { name: search, status: filterValue[0]?.value }, page: pageNumber });
      setSearchLoading(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [search, filterValue, pageNumber, briefPageSize]);

  const handelFilterChange = filterData => {
    if (filterData && filterData.length) {
      setFilterValue(filterData);
      let searchValue = { name: search };
      filterData.map(item => {
        const { id, value } = item;
        if (id === statusId) {
          return (searchValue.status = value);
        }
      });
    }
    if (!filterData.length) {
      setFilterValue('');
    }
  };
  return (
    <>
      <List2
        columns={columns}
        data={data}
        hasPagination={true}
        hasTotal={true}
        isLoading={listLoading || searchLoading}
        isFilterable={true}
        filters={filterValue || []}
        total={totalCount}
        onFiltersChange={handelFilterChange}
        isSearchable={true}
        search={search}
        onSearchChange={setSearch}
        isManualPagination
        fetchData={fetchData}
        pageIndex={pageIndex}
        onPageIndexChange={setPageIndex}
        pageCount={pageCount}
        defaultPageSize={defaultPageLimit}
        rowText="Active brief"
        rowsText="Active briefs"
        emptyState={
          <EmptyState layout="horizontal" size="small" image="create">
            <Paragraph>Uh oh, looks like you haven't create a data source yet</Paragraph>
          </EmptyState>
        }
        renderItem={(item, index) => {
          const {
            budgetAmount,
            briefTaskCount,
            budgetCurrency,
            createdAt,
            creatorName,
            endDate,
            h1,
            h2,
            name,
            originalId,
            ownerName,
            startDate,
            ownerEmail,
            status,
            logoUrl,
          } = item;
          const location = getBrandLocation(h1, clientHierarchy?.H1);
          const brand = getBrandLocation(h2, clientHierarchy?.H2);
          const hasWarning = index === 0;
          const response = handleProfilePicture(ownerEmail, profilePicture);

          return (
            <BriefCard key={originalId}>
              <BriefCard.Header
                subtitle={brand}
                title={name}
                owner={ownerName}
                uploadImage={logoUrl || ''}
                thumbnail={response ? response.data : ''}
              />
              <BriefCard.Body>
                <BriefCard.Body.ColOne>
                  <DescriptionList>
                    <DescriptionList.Term>Duration:</DescriptionList.Term>
                    <DescriptionList.Detail>
                      {startDate && <FormatDateTime isUnstyled mode="local" format="date" value={startDate} />}
                      {' — '}
                      {endDate && <FormatDateTime isUnstyled mode="local" format="date" value={endDate} />}
                    </DescriptionList.Detail>
                    <DescriptionList.Term>Markets:</DescriptionList.Term>
                    <DescriptionList.Detail>{location}</DescriptionList.Detail>
                    <DescriptionList.Term>Budget:</DescriptionList.Term>
                    <DescriptionList.Detail>
                      {budgetCurrency} <FormatNumber value={budgetAmount} isUnstyled />
                    </DescriptionList.Detail>
                    <DescriptionList.Term>Created by:</DescriptionList.Term>
                    <DescriptionList.Detail>
                      {creatorName}
                      {', '}
                      {createdAt && <FormatDateTime isUnstyled mode="local" format="date" value={createdAt} />}
                    </DescriptionList.Detail>
                  </DescriptionList>
                </BriefCard.Body.ColOne>

                <BriefCard.Body.ColTwo>
                  {status && (
                    <Chip variant="status" hasStatusLight status={statusVariances[status]}>
                      {status}
                    </Chip>
                  )}
                </BriefCard.Body.ColTwo>
                <BriefCard.Body.ColThree>
                  {status === 'In progress' ? (
                    <Link
                      iconRight="chevron-right"
                      url={`/client-workflow-hub/${originalId}`}
                      to={`/client-workflow-hub/${originalId}`}
                      as={RouterLink}
                      onClick={() => setHierarchyHandler()}
                    >
                      View details
                    </Link>
                  ) : (
                    <Link isDisabled iconRight="chevron-right">
                      View details
                    </Link>
                  )}
                </BriefCard.Body.ColThree>
              </BriefCard.Body>

              {/* {hasWarning ? (
                <BriefCard.Footer>
                  <DescriptionList isInline hasSeparator>
                    {hasWarning && (
                      <>
                        <DescriptionList.Term>
                          <Icon color="var(--dentsu-color-a500)" icon="warning-sign" size={14} /> Action required:
                        </DescriptionList.Term>
                        <DescriptionList.Detail isBold>
                          {briefTaskCount} Review brief from agency
                        </DescriptionList.Detail>
                      </>
                    )}
                  </DescriptionList>
                </BriefCard.Footer>
              ) : (
                ''
              )} */}
            </BriefCard>
          );
        }}
      />
    </>
  );
}
