import React from 'react';
import { getFormatDate } from 'modules/helper';
import { Paragraph, Card , Box } from '@dentsu-ui/components';

const GetMetaInfo = (props) => {
  const { updatedBy, lastUpdated } = props;

  return (
    <>
      <Card.Metadata>
        <Box display="flex" alignItems="baseline">
          <Box width="80px"> {'Updated by'}</Box>
          <Box ml="8px">
            <Paragraph style={{ fontSize: '12px' }}>{updatedBy}</Paragraph>
          </Box>
        </Box>
        <Box display="flex" alignItems="baseline">
          <Box width="80px">
            {' '}
            {'Last updated'}
          </Box>
          <Box ml="8px">
            <Paragraph style={{ fontSize: '12px' }}>
              {lastUpdated && (getFormatDate(lastUpdated))}
            </Paragraph>
          </Box>
        </Box>
      </Card.Metadata>
    </>
  );
};

export default GetMetaInfo;
