import React from 'react';
import { Card , Paragraph , Box , IconStatus } from '@dentsu-ui/components';
import { getFormatDate } from 'modules/helper';



const GetAttentionReq = (props) => {
  const { attentionReq } = props;
  const status = 'warning';
  return (
    <>
      <Card.Metadata>
       {'Attention required'}:
        <Box mt="4px">
          <Paragraph style={{ fontSize: '12px' }}>
            <IconStatus status={status} />{' '}
            {'Review by'}{' '}
            {getFormatDate(attentionReq)}
          </Paragraph>
        </Box>
      </Card.Metadata>
    </>
  );
};

export default GetAttentionReq;
