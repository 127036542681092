const envVars = process.env;

module.exports = {
  uri: `${envVars.REACT_APP_APOLLO_GRAPHQL_URI}/graphql`,
  mediaEcosystemUrl: envVars.REACT_APP_MEDIA_ECOSYSTEM_URL,
  campaignServiceKongUrl: envVars.REACT_APP_CAMPAIGN_SERVICE_KONG_URL,
  cwhServiceKongUrl: envVars.REACT_APP_CWH_SERVICE_KONG_URL,

  okta: {
    issuer: envVars.REACT_APP_OKTA_ISSUER || 'https://{yourOktaDomain}/oauth2/default',
    redirectUri: `${window.location.origin}/implicit/callback`,
    clientId: envVars.REACT_APP_CLIENT_ID || '{clientId}',
    pkce: true,
  },
};
