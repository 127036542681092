import React, { Component } from 'react';
import { EmptyState, Paragraph, Page } from '@dentsu-ui/components';

class ErrorBoundary extends Component {
  constructor() {
    super();
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.log(error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Page>
          <EmptyState
            data-testid="ErrorBoundaryState"
            layout="horizontal"
            size="large"
            image="void"
            title="There was a problem loading this section"
          >
            <Paragraph>Please try refresh the browser window again.</Paragraph>
          </EmptyState>
        </Page>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
