import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Cascader as CascaderComponent } from '@dentsu-ui/components';
import { useClientHierarchy } from 'hooks';

const Cascader = ({
  value,
  handleSave,
  label,
  handleClose,
  type,
  setHierarchy,
  handleSummery,
  ...restProps
}) => {
  const [hasChanges, setHasChanges] = useState(false);
  const {clientStructure} = useClientHierarchy({});

  let hierarchy = '';
  let hierarchyLevels = '';

  if (type === 'location') {
    hierarchy = 'H1';
  } else if (type === 'brand') {
    hierarchy = 'H2';
  } else {
    hierarchy = 'agency'
  }

  if (type === 'location') {
    hierarchyLevels = 'geoLevels';
  } else if (type === 'brand') {
    hierarchyLevels = 'brandLevels';
  } else {
    hierarchyLevels = 'agencyLevel'
  }
  return (
    <CascaderComponent
      label={label}
      levels={clientStructure?.hierarchyLevels}
      value={value}
      data={clientStructure ? [JSON.parse(JSON.stringify(clientStructure && clientStructure[hierarchy]))] : []}
      onChange={(newValue) => {
        if (!hasChanges && value !== newValue) setHasChanges(true);
        handleSave(newValue);
        handleSummery(newValue);
      }}
      onSelect={(item, activePaths) => {
        setHierarchy(activePaths);
      }}
      onExit={() => {
        if (hasChanges) {
          handleClose();
          setHasChanges(false);
        }
      }}
      {...restProps}
    />
  );
};

Cascader.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  type: PropTypes.string,
};

Cascader.defaultProps = {
  label: 'Location',
  type: 'location',
};

export default Cascader;
