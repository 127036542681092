import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  Divider,
  EmptyState,
  FormatCurrency,
  Icon,
  Paragraph,
  SegmentedControl,
  Stack,
  Dropdown,
  Statistic,
  Subheading,
  TableNext,
} from '@dentsu-ui/components';
import { PieChart } from './PieChart';
import { GET_BRIEF_SNAPSHOT_OVERVIEW } from '../graphql';
import { useLazyQuery } from '@apollo/client';
import { getFinancialYear, getFinancialDate, getClientCurrency, getFinancialYearDate } from 'modules/helper';
import { chartChannelColor } from 'constants';
import Loader from 'components/Loader';

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: ${props => (props.hasChart ? 'repeat(2, 1fr)' : 'repeat(1, 1fr)')};
  // grid-template-areas: 'panel1 chart' 'panel2 chart';
  //  grid-template-areas: 'panel1 panel2';
  grid-template-areas: ${props => (props.hasChart ? "'panel1 chart' 'panel2 chart'" : "'panel1 panel2'")};
  grid-column-gap: var(--dentsu-spacing-12);
  grid-row-gap: var(--dentsu-spacing-12);
  margin-block: var(--dentsu-spacing-16) var(--dentsu-spacing-32);
  
`;

const StyledPanel = styled.div`
  grid-area: ${props => props.gridArea};
  background-color: var(--dentsu-color-white);
  border: 1px solid var(--dentsu-color-n250);
  border-top-color: var(--dentsu-color-n350);
  padding: var(--dentsu-spacing-24);
`;

const iconColor = {
  neutral: 'var(--dentsu-color-b600)',
  positive: 'var(--dentsu-color-g600)',
  warning: 'var(--dentsu-color-amber)',
  error: 'var(--dentsu-color-red)',
};

export function Summary({ currency = 'USD', hasChart = false, clientStructure , finalFilter}) {

  // Columns for Tabular View of Data
  const mockColumns = useMemo(
    () => [
      {
        accessor: 'label',
        allowSort: true,
        Header: 'Channel group',
        id: 'label',
        type: 'text',
      },
      {
        accessor: 'value',
        allowSort: true,
        Header: 'Approved plan budget',
        id: 'value',
        options: { currency: currency },
        type: 'currency',
      },
      {
        accessor: 'percentage',
        allowSort: false,
        Header: 'Proportion of budget',
        id: 'percentage',
        options: { minimumFractionDigits: 2 },
        suffix: '%',
        type: 'numeric',
      },
    ],
    [currency],
  );

  // Chart View State - Chart or Table
  const [chartView, setChartView] = useState('chart');
  const [selectedFinancialYear, setSelectedFinancialYear] = useState('');
  const [snapshotOverview, setSnapshotOverview] = useState({});

  const [getSnapshotOverview, { loading: snapshotOverviewLoading}] = useLazyQuery(GET_BRIEF_SNAPSHOT_OVERVIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: snapshotResult => handleSnapshotOverview(snapshotResult),
  });

  useEffect(() => {
    if (selectedFinancialYear) {
      const endDate = new Date(
        new Date(selectedFinancialYear).setFullYear(new Date(selectedFinancialYear).getFullYear() + 1),
      );
      getSnapshotOverview({
        variables: {
          startDate: selectedFinancialYear,
          endDate: endDate.toISOString().split('T')[0],
          targetCurrency: getClientCurrency(clientStructure),
        },
      });
    } else {
      const startDate = getFinancialDate(clientStructure);
      if (startDate) {
        setSelectedFinancialYear(getFinancialYear(startDate));
      }
    }
  }, [clientStructure, selectedFinancialYear, finalFilter]);


  const handleSnapshotOverview = snapshot => {
    setSnapshotOverview(snapshot && snapshot.getCLPBriefSnapshotOverview);
  };

  const renderFinancialDropdown = () => (
    <Dropdown
      label={'Financial year'}
      value={selectedFinancialYear}
      onChange={(event) => setSelectedFinancialYear(event.target.value)}
    >
      {
        getFinancialYearDate(getFinancialDate(clientStructure)).map((financialYear) => (
          selectedFinancialYear && <Dropdown.Option value={financialYear?.year}>{financialYear?.label}</Dropdown.Option>
        ))
      }
    </Dropdown>
  );

  const {
    approvedBriefCount,
    totalApprovedBudgetOfBrief,
    approvedCount,
    approvedBudget,
    unApprovedBriefCount,
    totalUnApprovedBudgetOfBrief,
    unapprovedCount,
    unapprovedBudget,
    channelGroups
  } = snapshotOverview;

  const getCurrency = (amount, currency) => (
    <FormatCurrency
      isUnstyled
      value={amount || 0}
      currency={currency || "USD"}
      minimumFractionDigits={0}
    />
  );

  const getChartData = () => {
    let data = [];
    if (channelGroups && channelGroups.length) {
      chartChannelColor.forEach(item => {
        const selectedChannel = channelGroups.find(ele => ele.name === item.channel);
        if (selectedChannel && selectedChannel.approvedBudget > 0) {
          const temp = {
            id: selectedChannel.name.toLowerCase(),
            label: selectedChannel.name,
            percentage: selectedChannel.approvedPercentage,
            value: selectedChannel.approvedBudget,
            color: item.color,
            tip: getCurrency(selectedChannel.approvedBudget, getClientCurrency(clientStructure)),
          }
          data.push(temp);
        }
      });
    }
    return data;
  }

  const renderEmptyState = () => (
    <EmptyState
      title={'There isn\'t enough planned data yet'}
      layout="vertical"
      size="small"
      image={null}
    >
      <Paragraph>
        {'Plan have either not started or are not ready to be shared yet to show you how the budget is being split by channel'}
      </Paragraph>
    </EmptyState>
  );

  if(snapshotOverviewLoading) return <Loader />
  return (
    <>
      <Stack justifyContent="space-between">
        <Subheading>Summary</Subheading>
        {renderFinancialDropdown()}
      </Stack>

      <StyledGrid hasChart={hasChart}>
        <StyledPanel gridArea="panel1">
          <Stack alignItems="baseline" style={{ gap: '8px' }}>
            <Icon icon="warning-sign" color={iconColor['warning']} />
            <Subheading isSmaller>Pending approval</Subheading>
          </Stack>
          <Divider style={{ marginBlock: 'var(--dentsu-spacing-16)' }} />
          {Object.keys(snapshotOverview).length === 0 ? (
            <Box flex padding="4rem" textAlign="center">
              <Paragraph isBold style={{ paddingBottom: '1rem' }}>
                Nothing to approve
              </Paragraph>
              <Paragraph>Sit tight, we'll notify you when there's something ready for you to review.</Paragraph>
            </Box>
          ) : (
            <Stack style={{ gap: 16 }}>
              <Stack flex="1" flexDirection="column" style={{ gap: 16 }}>
                <Statistic>
                  <Statistic.Label>Briefs that require attention</Statistic.Label>
                  <Statistic.Content value={{ current: unApprovedBriefCount }} />
                </Statistic>
                <Statistic currency={currency}>
                  <Statistic.Label>Briefed budget pending approval</Statistic.Label>
                  <Statistic.Content
                    value={{
                      current: totalUnApprovedBudgetOfBrief,
                      format: {
                        style: 'currency',
                      },
                    }}
                  />
                </Statistic>
              </Stack>

              <Box>
                <Divider orientation="vertical" />
              </Box>

              <Stack flex="1" flexDirection="column" style={{ gap: 16 }}>
                <Statistic>
                  <Statistic.Label>Plans pending approval</Statistic.Label>
                  <Statistic.Content value={{ current: unapprovedCount }} />
                </Statistic>
                <Statistic currency={currency}>
                  <Statistic.Label>Planned budget pending approval</Statistic.Label>
                  <Statistic.Content value={{ current: unapprovedBudget, format: { style: 'currency' } }} />
                </Statistic>
              </Stack>
            </Stack>
          )}
        </StyledPanel>

        <StyledPanel gridArea="panel2">
          <Stack alignItems="baseline" style={{ gap: '8px' }}>
            <Icon icon="tick-circle" color={iconColor['positive']} />
            <Subheading isSmaller>Approved by</Subheading>
          </Stack>
          <Divider style={{ marginBlock: 'var(--dentsu-spacing-16)' }} />
          <Stack style={{ gap: 16 }}>
            <Stack flex="1" flexDirection="column" style={{ gap: 16 }}>
              <Statistic>
                <Statistic.Label>Total approved briefs</Statistic.Label>
                <Statistic.Content value={{ current: approvedBriefCount }} />
              </Statistic>
              <Statistic currency={currency}>
                <Statistic.Label>Total approved briefed budget</Statistic.Label>
                <Statistic.Content
                  value={{
                    current: totalApprovedBudgetOfBrief,
                    format: {
                      style: 'currency',
                    },
                  }}
                />
              </Statistic>
            </Stack>

            <Box>
              <Divider orientation="vertical" />
            </Box>

            <Stack flex="1" flexDirection="column" style={{ gap: 16 }}>
              <Statistic>
                <Statistic.Label>Total approved plans</Statistic.Label>
                <Statistic.Content value={{ current: approvedCount }} />
              </Statistic>
              <Statistic currency={currency}>
                <Statistic.Label>Total approved plan budget</Statistic.Label>
                <Statistic.Content value={{ current: approvedBudget, format: { style: 'currency' } }} />
              </Statistic>
            </Stack>
          </Stack>
        </StyledPanel>

        {hasChart && (
          <StyledPanel gridArea="chart">
            <Stack alignItems="baseline" justifyContent="space-between" style={{ gap: '8px' }}>
              <Subheading isSmaller>Approved plan budget split by channel group</Subheading>
              <SegmentedControl isIconOnly onChange={e => setChartView(e.target.value)} value={chartView}>
                <SegmentedControl.Button value="chart" icon="pie-chart">
                  Chart
                </SegmentedControl.Button>
                <SegmentedControl.Button value="table" icon="th-list">
                  Table
                </SegmentedControl.Button>
              </SegmentedControl>
            </Stack>
            <Divider style={{ marginBlock: 'var(--dentsu-spacing-16)' }} />
            
            <Box height="480px" style={{ overflow: 'auto' }}>
              {(channelGroups?.length && approvedBudget > 0) ? <PieChart currency={currency} approvedBudget={approvedBudget} data={getChartData()} /> : renderEmptyState()}
              {chartView === 'table' && (
                <TableNext
                  isSortable
                  columns={mockColumns}
                  data={getChartData()}
                  sortBy={[{ id: 'value', desc: true }]}
                />
              )}
            </Box>
          </StyledPanel>
        )}
      </StyledGrid>
    </>
  );
}