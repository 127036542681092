import {
  Wizard,
  FormField,
  TextInput,
  Textarea,
  Stack,
  Select,
  DateInput,
  Spacer,
  Link,
  Dropzone,
  FormGroup,
} from '@dentsu-ui/components';
import { useAtom } from 'jotai';
import { createBriefAtom } from 'store';

export function CreateBrief({ clientName }) {
  const [wizardOpen, setWizardOpen] = useAtom(createBriefAtom);

  return (
    <Wizard
      title={`Create brief`}
      isOpen={wizardOpen}
      actions={{
        primary: {
          label: 'Save and Close',
          icon: 'floppy-disk',
          onClick: () => setWizardOpen(false),
        },
        secondary: {
          label: 'Cancel',
          onClick: () => setWizardOpen(false),
        },
      }}
    >
      <Wizard.Step title="The basics">
        <FormField
          label="Give the brief a name"
          hint="Suggested name structure: Brand_Campaign_Market_Start Date (MM/YYYY). Maximum characters: 100"
        >
          <TextInput />
        </FormField>
        <FormField label="Select a market for this brief">
          <Select />
        </FormField>
        <FormField label="Choose the product for this brief">
          <Select />
        </FormField>
        <Stack>
          <FormField label="Campaign start date">
            <DateInput />
          </FormField>
          <Spacer width={8} />
          <FormField label="Campaign end date (optional)">
            <DateInput />
          </FormField>
        </Stack>
        <FormField
          label="Response date"
          hint="The date you want the agency to share how they'll deliver against this brief"
        >
          <DateInput />
        </FormField>
        <FormField
          label="Overall campaign budget"
          hint="The currency and total budget available for the campaign"
          isInline
        >
          <Select />
          <TextInput />
        </FormField>
        <FormField
          label="Supporting documents"
          hint="For example: creative assets, audience decks etc. Maximum file size: 25MB"
        >
          <Dropzone />
        </FormField>
      </Wizard.Step>
      <Wizard.Step title="Objectives, KPIs & channels">
        <FormGroup
          formHeader={{
            title: 'Objectives and KPIs (optional)',
          }}
        >
          <FormField label="What are the brief objectives" hint="Make sure they are specific and measurable">
            <Textarea placeholder="Brief objectives..." />
          </FormField>
          <FormField
            label="What are the business objectives?"
            hint={`What are the goals for the ${clientName} business?`}
          >
            <Textarea placeholder="Business objectives..." />
          </FormField>
          <FormField
            label="What are the marketing objectives?"
            hint="How will the marketing activity help achieve the business objective?"
          >
            <Textarea placeholder="Marketing objectives..." />
          </FormField>
          <FormField
            label="What are the overall KPIs?"
            hint="In order to be as specific as possible, select no more than three"
          >
            <Select />
          </FormField>
        </FormGroup>
        <FormGroup
          formHeader={{
            title: 'Channel-specific KPIs (optional)',
            description:
              "If there are any channel-specific KPI's you wish to include, select the channel they belong to first, then add up to two KPIs",
          }}
        >
          <FormField label="Select the channel these KPIs belong to">
            <Select />
          </FormField>
          <FormField label="Channel KPIs">
            <Select />
          </FormField>
          <Link iconLeft="plus">Add another channel</Link>
        </FormGroup>
        <FormGroup
          formHeader={{
            title: 'Channels to exclude (optional)',
            description: 'If there are any specific channels you wish to exclude, specify them here',
          }}
        >
          <FormField label="Select channel to exclude">
            <Select />
          </FormField>
          <Link iconLeft="plus">Add another exclusion</Link>
        </FormGroup>
      </Wizard.Step>
      <Wizard.Step
        title="Audience & geography"
        actions={{
          primary: {
            label: 'Submit brief',
            onClick: () => {
              setWizardOpen(false);
            },
          },
        }}
      >
        <FormGroup
          formHeader={{
            title: 'Audience (optional)',
          }}
        >
          <FormField label="Who's the primary audience?" hint="This detail can be added later if needed">
            <Textarea />
          </FormField>

          <Link iconLeft="plus">Add secondary audience</Link>
        </FormGroup>
        <FormGroup
          formHeader={{
            title: 'Geography (optional)',
          }}
        >
          <FormField label="What geographical areas would you like to target?">
            <TextInput />
          </FormField>
        </FormGroup>
        <FormField label="Any additional comments? (optional)" hint="What would help us make this brief a success?">
          <Textarea />
        </FormField>
      </Wizard.Step>
    </Wizard>
  );
}
