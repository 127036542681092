import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security, SecureRoute } from '@okta/okta-react';
import config from 'config';
import ProtectedRoute from 'protectedRoute';
import { ApolloProvider } from '@apollo/client';
import client from 'apolloClient';
import Authentication from 'components/Authentication';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { clientCode , tenantId} from './utils';

const App = () => {
  const history = useHistory();
  const oktaAuth = new OktaAuth(config.okta);
  
  // Filtering using H1 and H2
  const location = useLocation();
  const { search = '' } = location || {};
  const query = queryString.parse(search);
  const { h1, h2, agency } = query;
  // only set in case of notification url
  if (h1 && h2) {
    sessionStorage.setItem('hierarchy', JSON.stringify({ h1, h2, agency: agency || '*' }));
  }
  // Okta integration
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };
  const serviceConfig = { clientCode,tenantId};

  return (
    <>
      <Helmet titleTemplate="%s • dentsu connect" defaultTitle="dentsu connect" />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <Switch>
          <Route path="/implicit/callback">
            <LoginCallback />
          </Route>
          <SecureRoute path="/">
            <Authentication>
              <ApolloProvider client={client(serviceConfig)}>
                <ProtectedRoute />
              </ApolloProvider>
            </Authentication>
          </SecureRoute>
        </Switch>
      </Security>
    </>
  );
};

export default App;
