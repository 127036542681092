import React, { useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Divider,
  FormatNumber,
  FormatCurrency,
  FormatDateTime,
  Page,
  Paragraph,
  SegmentedControl,
  Stack,
  Collapsible,
  Circle,
  Subheading,
  Caption,
  Icon,
  Button,
} from '@dentsu-ui/components';
import { DescriptionList, TaskCard } from 'components';
import { clientHierarchyFormatter } from './helper';
import { GET_BRIEF, GET_WORKFLOW_BY_ID } from './graphql';
import { useQuery } from '@apollo/client';
import Loader from 'components/Loader';
import { useClientHierarchy, useOptionalLinks } from 'hooks';
import { BriefViewDetail } from 'modules/BriefViewDetail';
import { getBrandLocation } from 'modules/helper';

/**
 * Client Workflow Hub - Brief Detail Page
 */

export function BriefDetailPhase({ name, description, stage, children }) {
  const [show, setShow] = useState(true);
  const handleToggle = () => setShow(!show);

  return (
    <Box my="var(--dentsu-spacing-32)">
      <Stack style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => handleToggle()}>
        <Circle
          style={{ position: 'relative', top: '6px', marginRight: '16px' }}
          size={44}
          bgColor={'var(--dentsu-color-white)'}
          color={'var(--dentsu-color-black)'}
          borderSize={1}
          borderColor={'var(--dentsu-color-n250)'}
        >
          <Paragraph isBold isLarger>
            {stage}
          </Paragraph>
        </Circle>
        <Box mb="var(--dentsu-spacing-24)" flexGrow="1">
          <Subheading>{name}</Subheading>
          <Paragraph isSecondary>{description}</Paragraph>
        </Box>
        <Box pt={16}>
          <Icon icon={show ? 'chevron-up' : 'chevron-down'} color={'var(--dentsu-color-n700)'} />
        </Box>
      </Stack>

      <Collapsible isOpen={show} style={{ paddingLeft: '60px' }}>
        {children}
      </Collapsible>
    </Box>
  );
}

export function BriefDetail() {
  const [value, setValue] = useState('all');
  const [isTakeOverOpen, setIsTakeOverOpen] = useState(false);
  // useClientHierarchy Hooks
  const { clientStructure } = useClientHierarchy({});

  // Brief ID from URL path
  const { id: briefId } = useParams();

  // GET_OPTIONAL_LINK
  const { label, link } = useOptionalLinks();

  // GET BRIEF BY ID
  const { data: { getWorkflowById: { data: { phases: phaseData } = {} } = {} } = {} } = useQuery(GET_WORKFLOW_BY_ID, {
    variables: { briefId: briefId },
    fetchPolicy: 'cache-and-network',
  });

  // GET_BRIEF  query
  const { data: { getBrief: { data: briefData = {}  } = {} } = {} } = useQuery(GET_BRIEF, {
    variables: { id: briefId },
    fetchPolicy: 'cache-and-network',
  });
  const { budgetAmount, budgetCurrency, endDate, name, ownerName, startDate, h2 } = briefData.brief || {};
  let usersEmail = new Set();

  let marketName = '';
  if (briefData?.brief && clientStructure) {
    const { h1: H1 } = briefData.brief;
    usersEmail.add(briefData.brief.ownerEmail);
    marketName = clientHierarchyFormatter(H1, clientStructure.H1);
  }
  // Brand Name :
  const brand = getBrandLocation(h2, clientStructure?.H2);

  // Markup to return
  if (!briefData.brief) return <Loader />;

  return (
    <>
      <Page
        breadcrumbs={[
          {
            content: 'Back to briefs',
            url: '/briefs',
            as: RouterLink,
          },
        ]}
        // metadata="Market"
        metadata={<Caption isSecondary>{briefData?.brief && marketName && marketName.label}</Caption>}
        title={name}
        primaryAction={{
          content: 'View Microsoft Teams group',
          onClick: () => console.log('Create button clicked'),
          variant: 'tertiary',
          icon: 'brand-microsoft-teams',
          isDisabled: true,
        }}
        secondaryActions={
          label
            ? [
                {
                  content: label,
                  icon: 'share',
                  onClick: () => window.open(link, '_blank'),
                  variant: 'tertiary',
                  isDisabled: !label && !link,
                },
              ]
            : []
        }
      >
        <Stack alignItems="center" mt="-40px" mb="16px">
          <DescriptionList fontSize="medium" isInline hasSeparator>
            <DescriptionList.Term>Duration:</DescriptionList.Term>
            <DescriptionList.Detail>
              {startDate && <FormatDateTime isUnstyled mode="local" format="date" value={startDate} />}
              {' — '}
              {endDate && <FormatDateTime isUnstyled mode="local" format="date" value={endDate} />}
            </DescriptionList.Detail>
            <DescriptionList.Term>Budget:</DescriptionList.Term>
            <DescriptionList.Detail>
              {budgetCurrency} <FormatNumber value={budgetAmount} isUnstyled />
            </DescriptionList.Detail>
            <DescriptionList.Term>Owned by:</DescriptionList.Term>
            <DescriptionList.Detail>{ownerName}</DescriptionList.Detail>
            <DescriptionList.Term>
              <Button variant="ghost" size="small" onClick={() => setIsTakeOverOpen(true)}>
                View Brief
              </Button>
            </DescriptionList.Term>
            <DescriptionList.Detail></DescriptionList.Detail>
          </DescriptionList>

          <Box ml="auto">
            <SegmentedControl onChange={e => setValue(e.target.value)} value={value}>
              <SegmentedControl.Button value="all">All tasks</SegmentedControl.Button>
              <SegmentedControl.Button value="user">My tasks</SegmentedControl.Button>
            </SegmentedControl>
          </Box>
        </Stack>

        <Divider />

        {phaseData &&
          phaseData.length !== 0 &&
          phaseData.map((phase, index) => {
            const { _id, description, name, tasks } = phase;
            return (
              <>
                <BriefDetailPhase key={`p-${_id}`} name={name} description={description} stage={index + 1}>
                  {tasks.map(task => {
                    const { _id, subtasks } = task;
                    return (
                      <>
                        <TaskCard task={task} key={`t-${_id}`} />
                        {subtasks.map(subTask => (
                          <TaskCard key={`st-${subTask._id}`} isSubTask task={subTask} />
                        ))}
                      </>
                    );
                  })}
                </BriefDetailPhase>
              </>
            );
          })}
      </Page>
      <BriefViewDetail
        setIsTakeOverOpen={setIsTakeOverOpen}
        isTakeOverOpen={isTakeOverOpen}
        brand={brand}
        marketName={marketName}
        briefData={briefData}
      />
    </>
  );
}
