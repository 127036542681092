import { gql } from '@apollo/client';

export const GET_OPTIONAL_LINK = gql`
  query getOptionalLinks {
    getOptionalLinks {
      clientCode
      url
      label
    }
  }
`;

export const FETCH_PLAN_LIST = gql`
  query getCLPPlanSummaryOfBrief($targetCurrency: String!, $briefIds: [String!]!) {
    getCLPPlanSummaryOfBrief(targetCurrency: $targetCurrency, briefIds: $briefIds) {
      error {
        errorCode
        errorMessage
      }
      briefId
      unapprovedCount
      approvedCount
      totalCount
      unapprovedBudget
      approvedBudget
      totalBudget
      channelGroups {
        name
        percentage
        totalBudget
        totalCount
        approvedCount
        approvedBudget
        approvedPercentage
        unapprovedCount
        unapprovedBudget
        unapprovedPercentage
      }
    }
  }
`;
