import { Box, Stack, Subheading } from '@dentsu-ui/components';
import React from 'react';


const GetDeliverableName = (props) => {
  const { deliverableName, deliverableType } = props
  if (deliverableType) {
    return (
      <Stack mt="-8px">
        <Box pr={"10px"}>
          <Subheading isSmaller>
            {deliverableName}
          </Subheading>
        </Box>

      </Stack>
    )
  }
  return (
    <Stack>
    <Subheading >
      {deliverableName}
    </Subheading>
    </Stack>
  )
}

export default GetDeliverableName;
