import styled from 'styled-components';

const AppFooter = styled.footer`
  grid-area: footer;
  background-color: var(--dentsu-color-n100);
  display: flex;
  align-items: center;
  padding-block: var(--dentsu-spacing-4);
  padding-inline: var(--dentsu-spacing-32);
`;

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--dentsu-spacing-12);
  font-size: 12px;

  a {
    color: var(--dentsu-color-n700);
  }
`;

const NavPrimary = styled.div`
  position: relative;
  padding-inline-end: var(--dentsu-spacing-4);
  gap: var(--dentsu-spacing-12);

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    margin-top: -8px;
    width: 1px;
    height: 16px;
    background-color: var(--dentsu-color-n400);
  }
`;

const NavSecondary = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--dentsu-spacing-12);
`;

const Brand = styled.div`
  margin-inline-start: auto;
`;

const Logo = styled.img`
  opacity: 0.4;
  width: 76px;
  height: 12px;
`;

export const Styled = { AppFooter, Nav, Brand, Logo, NavPrimary, NavSecondary };
