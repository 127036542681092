import React from 'react';
import { Stack, Box, Paragraph } from '@dentsu-ui/components';
import { getChannelKpiNames } from 'modules/helper';
import { useChannelKpi, useKpiData } from 'hooks';
import { getChannelAndKpi } from '../helper';

const ObjectiveBrief = ({
  briefObjective,
  businessObjective,
  marketingObjective,
  overallKpi,
  channelKpi,
  excludeChannel,
}) => {
  const { channelKpiData: channelsData } = useChannelKpi([]);
  const { kpiData } = useKpiData([]);
  const newChannelKpi = getChannelKpiNames(channelsData, kpiData, channelKpi);
  const newExcludeChannel = getChannelKpiNames(channelsData, kpiData, excludeChannel);

  const handleOverallKpi = () => {
    return overallKpi.map((overallKpi, index) => {
      return (
        <Paragraph>
          {'KPI'} {index + 1} - {overallKpi.overallKPIName}
        </Paragraph>
      );
    });
  };

  return (
    <>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Brief objectives'}</Paragraph>
          <Paragraph>{briefObjective || '-'}</Paragraph>
        </Box>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Business objectives'}</Paragraph>
          <Paragraph>{businessObjective || '-'}</Paragraph>
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Marketing objectives'}</Paragraph>
          <Paragraph>{marketingObjective || '-'}</Paragraph>
        </Box>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Overall KPIs'}</Paragraph>
          {!overallKpi ? <Paragraph>{'-'}</Paragraph> : handleOverallKpi()}
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Included channels & KPIs'}</Paragraph>
          {!channelKpi && <Paragraph>{'-'}</Paragraph>}
          <Paragraph>{getChannelAndKpi(newChannelKpi)}</Paragraph>{' '}
        </Box>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Excluded channels'}</Paragraph>
          {!excludeChannel && <Paragraph>{"-"}</Paragraph>}
          <Paragraph>
            { newExcludeChannel?.map((channel, index) => {
                return (
                  <Paragraph>
                    {index + 1}. {channel.channelName || "-"}
                  </Paragraph>
                );
              })}
          </Paragraph>
        </Box>
      </Stack>
    </>
  );
};

export default ObjectiveBrief;
