import { useRef, useState } from 'react';
import { Modal, FormField, Scrollable, VisualPicker, Button } from '@dentsu-ui/components';
import { Dropzone } from './components/Dropzone';
import { useAllBriefLogo } from 'hooks';
import { useMutation } from '@apollo/client';
import { SCAN_IMAGE, UPLOAD_IMAGE } from './graphql';
import { getHash, handleValidate } from './helper';

export function MediaBrowser({ setShowMediaBrowser, showMediaBrowser, activeBrief, handlePublishNow, refetch }) {
  const [selectedValues, setSelectedValues] = useState('');
  const [uploadStatus, setUploadStatus] = useState(true);
  const [isImageScanning, setIsImageScanning] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [imagePath, setImagePath] = useState('');

  const { briefLogo, refetchLogo } = useAllBriefLogo();

  let imageUid = useRef();

  const imageList = briefLogo?.map(item => {
    return { image: item.imageUrl, value: item.imageId };
  });

  const [scanMedia] = useMutation(SCAN_IMAGE);
  const handleScanImage = imageFile => {
    handleValidate(imageFile)
      .then(fileUrl => {
        const imageid = getHash(fileUrl).toString();
        imageUid.current = imageid;
        setImagePath(JSON.stringify(fileUrl));
        setErrorMessage('Image scanning in progress...');
        setUploadStatus(false);
        setIsImageScanning(true);
        scanMedia({
          variables: {
            imageId: imageUid.current,
            scanImage: true,
            imageUrl: fileUrl,
          },
        })
          .then(({ data: { uploadClientLogo } }) => {
            if (uploadClientLogo && !uploadClientLogo.isValidImage) {
              setErrorMessage('Oops, something went wrong. Please remove the file and try uploading again');
              setUploadStatus(true);
            } else {
              setErrorMessage('');
            }
            setIsImageScanning(false);
          })
          .catch(() => {
            setErrorMessage('Oops, something went wrong. Please remove the file and try uploading again');
            setUploadStatus(true);
            setIsImageScanning(false);
          });
      })
      .catch(err => {
        setErrorMessage(err);
        setUploadStatus(true);
      });
  };
  const [uploadMedia, { loading: mediaLoading }] = useMutation(UPLOAD_IMAGE);
  const onUploadImage = async () => {
    setShowMediaBrowser(false);
    uploadMedia({
      variables: { scanImage: false, briefId: activeBrief?.originalId, imageId: imageUid.current || selectedValues[0] },
    }).then(() => {
      handlePublishNow();
      refetch();
      refetchLogo();
    });
  };
  const handleRemove = () => {
    setErrorMessage('');
    setUploadStatus(true);
  };

  const handleMediaBrowser = () => {
    setShowMediaBrowser(false);
    setSelectedValues('');
  };

  return (
    <Modal isOpen={showMediaBrowser} onClose={handleMediaBrowser}>
      <Modal.Header metadata="Change brief image" title={activeBrief?.name} />
      <Modal.Tabs>
        <Modal.TabList>
          <Modal.Tab label="Browse files" />
          <Modal.Tab label="Add new file" />
        </Modal.TabList>
        <Modal.TabPanels>
          <Modal.TabPanel>
            <FormField
              label="Select a file"
              hint="These files have already uploaded and associated with the selected client"
            >
              <Scrollable height={400}>
                <VisualPicker
                  cols={3}
                  options={imageList}
                  selectedValues={selectedValues}
                  onChange={updatedSelectedValues => {
                    setSelectedValues(updatedSelectedValues);
                  }}
                />
              </Scrollable>
            </FormField>
          </Modal.TabPanel>
          <Modal.TabPanel>
            <FormField
              error={errorMessage}
              label="Upload file"
              hint="We recommend you to upload an image with transparent background (e.g. a png file no larger than 300kb and no larger than 300 by 300 px)"
            >
              <Dropzone
                onAddFileStart={e => handleScanImage(e)}
                instantUpload={false}
                allowFileSizeValidation={true}
                maxFileSize={'300KB'}
                acceptedFileTypes={['image/png']}
                onRemoveFile={() => handleRemove()}
                itemInsertLocation="before"
                allowDrop={true}
              />
            </FormField>
          </Modal.TabPanel>
        </Modal.TabPanels>
      </Modal.Tabs>

      <Modal.Footer>
        <Button
          onClick={() => onUploadImage()}
          isLoading={!isImageScanning && !uploadStatus && mediaLoading}
          isDisabled={!selectedValues && (uploadStatus || isImageScanning)}
        >
          Save change
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
