export const getContractType = (contractType) => {
    if (contractType === "ST") return 'Short term / interim contract'
    else if (contractType === "MSA") return 'MSA (Master service agreement)'
    else return 'MSA with local addendums'
  }

export const getDurationType = (durationType) => {
    if (durationType === "Fixed") return 'Fixed start / end dates'
    else if (durationType === "Rolling") return 'Rolling contract after initial term'
    else return 'Auto-renew on a specific date'
  }

export const getFormattedDate = (value) => {
    const date = new Date(value);
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];
    const day = date.getDate();
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  }

export const clientDetailsQuestion = {
    "heading": "Client and agency brand details",
    "about": "About this client",
    "clientType": "Is this a global or local (domestic) client?",
    "agencyType": "Where is the lead agency for this client based?",
    "sector": "What sector is this client?",
    "country": "What countries will this client operate in?",
    "leadHeading": "Lead agency brand",
    "leadAgency": "Lead agency brand",
    "supportingHeading": "Supporting agency brand(s)",
    "supportingAgencies": "Supporting agencies",
    "agreementHeading": "Agreement",
    "contDuration": "Duration of contract",
    "contType": "What kind of contract do you have for this client?",
    "durationType": "Duration type",
    "startDate": "Start date",
    "endDate": "End date"
}