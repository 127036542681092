import gql from "graphql-tag";

export const GET_APP_TENANTS = gql `
        query getAppTenants {
            getAppTenants {
            tenantApps {
                appCode
                appName
                clientLevel
                clients {
                clientCode
                clientName
                tenantId
                navUri
                value
                label
                }
            }
            }
        }
`
