import { Chip } from '@dentsu-ui/components';
import React from 'react';
import { setColorVariant, statusTransform } from '../constant';

const GetStatus = (props) => {
  const { status } = props;

  return (
    <Chip variant="status" status={setColorVariant(status)} hasStatusLight>
      {`${[statusTransform[status]]}`}
    </Chip>
  );
};

export default GetStatus;
