import styled from 'styled-components';
const AppHeader = styled.header`
  grid-area: header;
  width: 100%;
  height: 60px;
  background: var(--dentsu-color-n1000);
  color: var(--dentsu-color-white);
  display: flex;
  align-items: center;
`;
const Home = styled.div`
  background: var(--dentsu-color-n950);
  color: var(--dentsu-color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  &:hover {
    color: var(--dentsu-color-white);
  }
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  gap: var(--dentsu-spacing-8);
  padding-inline: var(--dentsu-spacing-32);
`;
const PrimaryTitle = styled.span`
  font-size: var(--dentsu-font-size-14);
  margin-top: 2px;
  letter-spacing: 1.6px;
  font-weight: var(--dentsu-font-weight-medium);
  text-transform: uppercase;
`;
const SecondaryTitle = styled.span`
  font-weight: var(--dentsu-font-weight-light);
  opacity: 0.8;
  margin-top: 2px;
  font-size: var(--dentsu-font-size-14);
`;
const Utilities = styled.div`
  display: flex;
  align-items: center;
  margin-inline-start: auto;
  gap: var(--dentsu-spacing-20);
  padding-inline: var(--dentsu-spacing-32);
`;
const User = styled.div`
  display: flex;
  align-items: center;
  gap: var(--dentsu-spacing-4);
`;
const IconButton = styled.div`
  button {
    width: 1rem;
    height: 1rem;
    svg {
      height: 20px;
      width: 20px;
      color: var(--dentsu-color-white);
    }
    &:hover {
      background: var(--dentsu-color-n1000) !important;
      border: none;
    }
    &:active {
      background: var(--dentsu-color-n1000);
    }
    &:focus {
      box-shadow: none;
    }
  }
`;
export const Styled = { AppHeader, Home, Title, PrimaryTitle, SecondaryTitle, Utilities, User, IconButton };
