import { useState, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { BriefList } from './components/BriefList';
import { mockColumns } from '__mocks__/mockData';
import { FETCH_PLAN_LIST } from './graphql';
import { Layout } from '@dentsu-ui/components';
import { useBriefList, useClientHierarchy } from 'hooks';
import { getAllOriginalId, getClientCurrency } from 'modules/helper';

export function BriefImages() {
  const [briefLogo, setBriefLogo] = useState([]);
  const initialFilterOptions = { H1: '', H2: '', agency: '' };
  const briefListColumns = useMemo(() => mockColumns, []);
  // local state
  const [briefPlanList, setBriefPlanlist] = useState([]);

  const { clientName, clientStructure } = useClientHierarchy({});

  const { briefDataList, loading, refetch, totalCount , briefPageSize, setBriefPageSize } = useBriefList();

  const handleAllimage = image => {
    const getAllLogo = image && image.getAllBriefLogo.data;
    if (getAllLogo && getAllLogo.length) {
      setBriefLogo(getAllLogo);
    }
  };

  // const { loading: imageLoading } = useQuery(GET_ALL_BRIEF_LOGO, {
  //   variables: {
  //     active: true,
  //   },
  //   notifyOnNetworkStatusChange: true,
  //   fetchPolicy: 'no-cache',
  //   onCompleted: image => handleAllimage(image),
  // });

  // FETCHING FETCH_PLAN_LIST
  const originalId = getAllOriginalId(briefDataList);

  useQuery(FETCH_PLAN_LIST, {
    variables: {
      targetCurrency: getClientCurrency(clientStructure),
      briefIds: originalId,
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: result => handleBriefPlanList(result),
  });

  const handleBriefPlanList = data => {
    const planList = data && data.getCLPPlanSummaryOfBrief;
    setBriefPlanlist(planList || []);
  };

  return (
    <Layout>
      <Layout.Section>
        <Layout.Panel title="Brief Images">
          <BriefList
            columns={briefListColumns}
            planData={briefPlanList}
            briefPageSize={briefPageSize}
            setBriefPageSize={setBriefPageSize}
            briefIdList={originalId}
            listLoading={loading}
            clientName={clientName}
            data={briefDataList}
            refetch={refetch}
            totalCount={totalCount}
            clientHierarchy={clientStructure}
          />
        </Layout.Panel>
      </Layout.Section>
    </Layout>
  );
}
