import { gql } from '@apollo/client';

export const GET_BRIEF_ATTENTION_COUNT = gql`
  query getBriefAttentionCount($input: getAttentionCount) {
    getBriefAttentionCount(input: $input) {
      data {
        brief
        tasks
        deliverableCount
      }
      metadata {
        message
      }
    }
  }
`;

export const GET_BRIEF_STATUS = gql`
  query {
    getBriefStatus
  }
`;

export const GET_BRIEF = gql`
  query getBrief($id: ID!) {
    getBrief(id: $id) {
      data {
        brief {
          h1
          h2
          name
          budgetAmount
          budgetCurrency
          startDate
          status
          endDate
          responseDate
          leadAgency
          originalId
          ownerName
          ownerEmail
          owner
          geography
          comments
          id
          briefReference
          briefObjective
          businessObjective
          marketingObjective
          lastAction
          fundingSource
          version
          approverName
          approvedDate
        }
        briefDocuments {
          id
          briefId
          originalId
          documentType
          documentName
          documentFileType
          createdAt
          documentFileSize
        }
        briefOverallKPIs {
          originalId
          overallKPIName
        }
        briefAudience {
          id
          audience
          isPrimary
        }
        excludeChannels {
          channelCode
          clientCode
        }
        channelKPIs {
          channelCode
          clientCode
          kpi{
            kpiId
          }
        }
      }
    }
  }
`;

export const GET_WORKFLOW_BY_ID = gql`
  query getWorkflowById($briefId: String) {
    getWorkflowById(briefId: $briefId) {
      data {
        originalId
        name
        agency {
          value
          label
        }
        client {
          value
          label
        }
        market {
          value
          label
        }
        channels {
          label
        }
        teamUrl
        msTeam
        phases {
          name
          description
          priority
          originalId
          required
          tasks {
            originalId
            name
            required
            priority
            isApprovalRequired
            isInternalApproval
            isClientApproval
            completedDate
            blockedReason
            collaborator {
              oktaId
              email
            }
            isSetClientDueDate
            app {
              isGlobal
              name
              url
              message
              type
              mefAppId
              isPrimary
            }
            assignedTo {
              mail
              displayName
              oktaId
            }
            assignedBy {
              mail
              displayName
              oktaId
            }
            status
            createdAt
            clientDueDate
            internalDueDate
            originalId
            deliverables {
              originalId
            }
            subtasks {
              originalId
              priority
              name
              required
              isApprovalRequired
              isInternalApproval
              isClientApproval
              completedDate
              isSetClientDueDate
              blockedReason
              collaborator {
                oktaId
                email
              }
              app {
                isGlobal
                name
                url
                message
                type
                mefAppId
                isPrimary
              }
              assignedTo {
                mail
                displayName
                oktaId
              }
              assignedBy {
                mail
                displayName
                oktaId
              }
              status
              createdAt
              clientDueDate
              internalDueDate
              originalId
              deliverables {
                originalId
              }
            }
          }
        }
        description
      }
      metadata {
        code
        message
      }
    }
  }
`;
