import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Menu, Button, Icon, Box, Image, Stack } from '@dentsu-ui/components';


export const getRgbaColor = (hexColor) => {
  const color = hexColor.replace('#', '');
  const aRgbHex = color.match(/.{1,2}/g);
  const rgbaColor = [
    `${parseInt(aRgbHex[0], 16)},`,
    `${parseInt(aRgbHex[1], 16)},`,
    `${parseInt(aRgbHex[2], 16)}`,
  ];
  return rgbaColor;
};

export const ProfileWrapper = styled.div`
  cursor: pointer;
  background: var(--dentsu-color-b500);
`;

export const StyledChrome = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  @media print {
    * {
      overflow: visible !important;
    }
  }
`;

export const StyledChromeContainer = styled.div`
  display: flex;
  flex: 1;
  .dentsu-popover {
    border-top: none;
  }
`;

export const StyledChromeMain = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledChromeHeader = styled.div`
  height: 60px;
  padding-right: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  background: var(--dentsu-color-n100);
  @media print {
    display: none;
  }
`;

export const StyledChromeSwitcherLogo = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-radius: 0px;
  width: 60px;
  cursor: pointer;
  &:focus {
    box-shadow: none;
  }
`;

export const StyledChromeLogo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 60px;
  background: var(--dentsu-color-b500);

  img {
    width: 20px;
    height: 20px;
  }
`;

export const StyledChromeHeaderLogo = styled(Box)`
  height: 60px;
  border-radius: 0px;
  width: 60px;
`;

export const StyledChromeSwitcher = styled.div``;

export const StyledChromeContent = styled.div`
  flex: 1;
  display: flex;
`;

export const StyledChromeFooter = styled.div`
  width: 100%;
  height: 44px;
  padding: 0 32px;
  align-items: center;

  display: flex;
  font-size: 12px;
  color: var(--dentsu-color-black);
  background: var(--dentsu-color-n100);
  @media print {
    display: none;
  }
`;

export const StyledChromeFlex = styled.span`
  flex: 1;
`;

export const StyledChromeCanvas = styled.div`
  position: relative;
  overflow: auto;
  flex: 1;
  display: flex;
  width: auto;
  background: #f6f8f9;
  scroll-behavior: smooth;
  /* transition: all 500ms ease-in-out; */

  &.fullscreen {
    z-index: 1;
    margin-top: -60px;
    margin-bottom: -44px;
  }
`;

export const StyledChromeLanguage = styled.select`
  color: var(--dentsu-color-n0);
  outline: none;
  border: none;
  background: none;
  font-size: 14px;
  font-weight: var(--dentsu-font-weight-medium);
  cursor: pointer;
`;

export const StyledChromeLanguageOptions = styled.option`
  color: #000;
`;

export const StyledChromePanel = styled.div`
  overflow: auto;
  width: 300px;
`;

export const StyledChromeAnnouncements = styled.div``;

export const StyledChromeTitle = styled.div`
  color: var(--dentsu-color-n0);
  text-transform: uppercase;
  letter-spacing: 1.6px;
  margin-left: 32px;
  margin-right: 16px;
  font-size: 16px;
  font-weight: var(--dentsu-font-weight-medium);
`;

export const StyledChromeSubTitle = styled.div`
  color: var(--dentsu-color-n0);
  font-size: 14px;
  letter-spacing: 0.8px;
  margin-right: 20px;
  font-weight: var(--dentsu-font-weight-medium);
  opacity: 0.8;
`;

export const StyledChromeDivider = styled.div`
  border-left: 1px solid var(--dentsu-color-n700);
  height: 28px;
  margin-right: 11px;
`;

export const StyledChromeFooterLink = styled.a`
  margin: 2px 10px;
  color: var(--dentsu-color-n700);
  text-decoration: none;
  &:hover {
    color: var(--dentsu-color-n700);
    text-decoration: underline;
  }
`;

export const StyledChromeFooterDivider = styled.div`
  border-left: 1px solid #ceced6;
  height: 24px;
  margin: 0 10px 0;
`;

export const StyledChromeFooterFeedbackLink = styled.a`
  margin: 0 10px 0;
  text-decoration: none;
  font-weight: var(--dentsu-font-weight-medium);
`;

export const StyledChromeFooterLogo = styled.img`
  height: 12px;
  width: 76px;
  opacity: 0.4;
`;

export const StyledChromeLogout = styled.span`
  cursor: pointer;
  font-weight: var(--dentsu-font-weight-medium);
  font-size: 13px;
  margin: 0 16px;
  color: var(--dentsu-color-n0);
`;

export const StyledChromeButton = styled.button`
  width: 40px;
  height: 40px;
  display: block;
  background: transparent;
  border: none;
  color: var(--dentsu-color-white);
  outline: none;
  cursor: pointer;
  /* position: fixed;
  bottom: 54px;
  left: 10px;
  z-index: 2; */

  strong {
    display: block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    background: var(--dentsu-color-n0);
    color: #000;
    border-radius: 14px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-left: 5px;
`;

export const StyleProfileMenuButton = styled(Menu.Button)`
  color: var(--dentsu-color-n0) !important;
  width: 1.25rem !important;
  &:hover {
    background-color: var(--dentsu-color-n1000) !important;
  }
  &:focus {
    box-shadow: none !important;
  }
`;

export const StyledMenuEmail = styled(Menu.Item)`
  * {
    opacity: 100%;
  }
`;

export const StyledHelp = styled(Image)`
  cursor: pointer;
  margin-left: 20px;
  margin-right: 20px;
`;

export const StyledChromeStack = styled(Stack)`
  align-items: center !important;
`;

export const ProfileSwitcherSection = styled.div`
  // opacity: ${props => (props.isOpen ? 1 : 0)};
  transition: all 0.11s ease 0s;
`;

export const ProfileSwitcherContainer = styled.div`
  position: relative;
`;

export const ProfileSwitcherButton = styled.button`
  height: 34px;
  width: 34px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  outline: none;
  border: none;
  padding: 0;
  background: transparent;
  color: #262626;
  cursor: pointer;
  transition: all 0.11s ease 0s;
  &:hover {
    opacity: 0.8;
  }
`;

export const ProfileSwitcherCurtain = styled.div`
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: 4;
`;

export const IconWrapper = styled.div`
position: absolute;
z-index: 999;
`;
