import { gql } from '@apollo/client';

export const GET_BRIEF_SNAPSHOT_OVERVIEW = gql`
  query getCLPBriefSnapshotOverview($startDate: String!, $endDate: String!, $targetCurrency: String!) {
    getCLPBriefSnapshotOverview(
      financialYear: { startDate: $startDate, endDate: $endDate }
      targetCurrency: $targetCurrency
    ) {
      totalApprovedBudgetOfBrief
      totalUnApprovedBudgetOfBrief
      approvedBriefCount
      unApprovedBriefCount
      numberOfBriefs
      channelGroups {
        approvedBudget
        approvedCount
        approvedPercentage
        totalBudget
        totalCount
        name
        percentage
        unapprovedPercentage
        unapprovedBudget
        unapprovedCount
      }
      unapprovedCount
      approvedCount
      unapprovedBudget
      approvedBudget
      totalCount
      totalBudget
    }
  }
`;

export const GET_CLIENT_BANNER = gql`
  query {
    getCLPClientBanner{
      bannerImageUrl
      clientCode
    }
  }
`;

export const GET_BRIEF_STATUS = gql`
  query {
    getBriefStatus
  }
`;



export const GET_CLIENT_LOGO = gql`
  query {
    getCLPClientLogo{
      clientCode
      logoImageUrl
    }
  }
`;

export const FETCH_CLIENT_STRUCTURE = gql`
  query getCLPClientStructure {
    getCLPClientStructure {
      data
      message
      status
    }
  }
`;

export const FETCH_AGENCY = gql`
  query {
    getAgencies {
      data{
      name
      code
      countries{
        code
        name
      }
    }
    status
    message
    }
  }
`;

export const FETCH_BRIEF_LIST = gql`
  query getBriefs($page: Int, $pageSize: Int, $leadAgency: String, $sortField: String, $sortOrder: SORT_ORDER, $search: ClpSearchInput) {
    getBriefs(page: $page, pageSize: $pageSize, leadAgency: $leadAgency, sortField: $sortField, sortOrder: $sortOrder, search: $search) {
      pageSize
      page
      totalCount
      briefs {
        briefTaskCount
        name
        budgetCurrency
        budgetAmount
        startDate
        endDate
        status
        originalId
        h1
        h2
        logoUrl
        whoWillApprove
        isCreatedByClient
        lastAction
        lastEditAction {
          status
          app
        }
        creatorName
        owner
        ownerName
        ownerEmail
        createdAt
        schemaDocumentVersion
      }
    }
  }
`;  

export const FETCH_PLAN_LIST = gql`
  query getCLPPlanSummaryOfBrief(
    $targetCurrency: String!
    $briefIds: [String!]!) {
    getCLPPlanSummaryOfBrief(
      targetCurrency: $targetCurrency
      briefIds: $briefIds
    ) {
      error {
        errorCode
        errorMessage
      }
      briefId
      unapprovedCount
      approvedCount
      totalCount
      unapprovedBudget
      approvedBudget
      totalBudget
      channelGroups {
        name
        percentage
        totalBudget
        totalCount
        approvedCount
        approvedBudget
        approvedPercentage
        unapprovedCount
        unapprovedBudget
        unapprovedPercentage
      }
    }
  }
`;

export const FETCH_TEAMS_PROFILE = gql`
query getCLPAllUsersProfileByEmails($emails: [String]) {
  getCLPAllUsersProfileByEmails(emails: $emails) {
    data{
      usersProfileName{
        lastName
        firstName
        email
      },
      usersProfilePicture{
        data
        isError
        email
      }
    }
  }
}
`;


export const GET_AGENCIES = gql`
query getAgencies {
  getAgencies{
    data{
      name
    }
  }
}

`;