import { useState } from 'react';
import { Modal, Button, Select, FormField } from '@dentsu-ui/components';
import { useAtom } from 'jotai';
import { switchClientAtom , clientCodeAtom, tenantIdAtom } from '../../store';
import { useQuery } from '@apollo/client';
import { GET_APP_TENANTS } from './graphql/query';
import { useHistory } from "react-router-dom";

export function SwitchClient() {
  const [modalOpen, setModalOpen] = useAtom(switchClientAtom);
  const [selectClient, setSelectClient] = useState();
  const [switchData , setSwitchData] = useState([]);
  const [ clientStore , setClientStore] = useAtom(clientCodeAtom);
  const [ tenantStore , setTenantStore] = useAtom(tenantIdAtom);
  let history = useHistory();

 useQuery(GET_APP_TENANTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    onCompleted: client => handleClientCode(client),
  });

  const handleClientCode = client => {
    const switchClient = client && client.getAppTenants.tenantApps;
    const clpApp = switchClient[0]?.clients || [];
    const cwhApp = switchClient[1]?.clients || [];
    const commonClient = clpApp?.filter(app =>
      cwhApp?.some(({ clientCode, clientName }) => app.clientCode === clientCode && app.clientName === clientName),
    );
    const clientData = commonClient?.map(item => {
      return { label: item.clientName,
               value: item.clientCode,
               clientCode:item.clientCode,
               clientName:item.clientName,
               tenantId:item.tenantId,
               navUri:item.navUri
        };
    });
    setSwitchData(clientData);
  };
  const switchClients = () => {
    setModalOpen(false);
    history.go();
    setClientStore(selectClient.clientCode);
    setTenantStore(selectClient.tenantId);
    sessionStorage.setItem('hierarchy', JSON.stringify({ h1: '', h2: '', agency: '*' }));
  }
  return (
    <Modal width="496px" isOpen={(!clientStore && !tenantStore && switchData?.length ) ? !modalOpen : modalOpen} onClose={() => setModalOpen(false)}>
      <Modal.Header title="Switch Client" />
      <Modal.Body>
        <FormField label="Choose a client">
          <Select
            menuPosition="fixed"
            options={switchData}
            onChange={selected => {
              setSelectClient(selected);
            }}
            value={selectClient}
          />
        </FormField>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={switchClients}>Switch</Button>
      </Modal.Footer>
    </Modal>
  );
}
