import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import config from '../config';

const { uri } = config;

const httpLink = createHttpLink({
  uri,
});


const authLink = (serviceconfig)  => setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const storage = localStorage.getItem('okta-token-storage');
  const token = JSON.parse(storage)?.accessToken?.accessToken;
  const { clientCode, tenantId } = serviceconfig;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      serviceconfig: JSON.stringify({
        clientCode,
        tenantId,
        appCode: 'CWH',
        ...JSON.parse(sessionStorage.getItem('hierarchy')),
      }),
    },
  };
});
const client = (serviceconfig) => new ApolloClient({
  link: authLink(serviceconfig).concat(httpLink),
  cache: new InMemoryCache(),
});

export default client;
