import { Dropzone as DentsuDropzone } from '@dentsu-ui/components';
import styled from 'styled-components';

const DropzoneRoot = styled('div')`
  .dentsu-dropzone {
    max-width: none;
  }
`;

export function Dropzone(props) {
  return (
    <DropzoneRoot>
      <DentsuDropzone {...props} />
    </DropzoneRoot>
  );
}
