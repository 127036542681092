import gql from "graphql-tag";

export const GET_ALL_BRIEF_LOGO = gql `

query getAllBriefLogo($active:Boolean!){
    getAllBriefLogo(active: $active){
      message
      status
      data{
        imageUrl
        id
        originalId
        clientCode
        active
        imageId
      }
    }
  }

`;