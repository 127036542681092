import { Box, EmptyState, List, List2, Paragraph } from '@dentsu-ui/components';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DeliverableCard from './DeliverableCard/DeliverableCard';
import { mockColumns } from '../constant';

const PanelList = (props) => {
  const { panelData, loading, briefName, briefId, refetch } = props;
  const deliverableColumns = useMemo(() => mockColumns, []);

  const [search, setSearch] = useState('');
  const [filterValue, setFilterValue] = useState([]);
  const [defaultPageLimit, setDefaultPageLimit] = useState(10);
  const [searchLoading, setSearchLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const totalCount = panelData?.length;

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      setDefaultPageLimit(pageSize);
      setPageCount(Math.ceil(totalCount / pageSize));
    },
    [search, filterValue, totalCount],
  );

  useEffect(() => {
    let deliverableListParams = {
      briefId: briefId,
    };
    if (filterValue?.length) {
      deliverableListParams = {
        ...deliverableListParams,
        clientApprovalStatus: filterValue[0]?.value,
      };
    }
    if (search?.length) {
      deliverableListParams = {
        ...deliverableListParams,
        search: search,
      };
    }
    setSearchLoading(true);
    refetch({ deliverableListParams });
    setSearchLoading(false);
  }, [filterValue, search]);

//   if (!panelData?.length) {
//     return (
//       <EmptyState
//         layout="horizontal"
//         size="small"
//         image="waiting"
//         title={'There are no deliverables ready for review yet'}
//       >
//         <Paragraph>
//           {
//             'It looks like no deliverables have been added for this brief yet. Please check back later or get in touch with your team for more details.'
//           }
//         </Paragraph>
//       </EmptyState>
//     );
//   }
  return (
    <Box>
      <List2
        columns={deliverableColumns}
        id="deliverable-list"
        data={panelData || []}
        hasPagination={true}
        hasTotal={true}
        isLoading={loading || searchLoading}
        isFilterable={true}
        search={search}
        filters={filterValue || []}
        total={totalCount}
        onFiltersChange={setFilterValue}
        isSearchable={true}
        onSearchChange={setSearch}
        isManualPagination
        fetchData={fetchData}
        pageIndex={pageIndex}
        onPageIndexChange={setPageIndex}
        pageCount={pageCount}
        defaultPageSize={defaultPageLimit}
        rowText="results in total"
        rowsText="results in total"
        searchText={'Search'}
        clearFiltersText="Clear filters"
        gridColumns={1}
        emptyState={
            filterValue?.length || search?.length ?
          <EmptyState layout="vertical" size="small" image={null} title={'No results found'}>
            <Paragraph>{'Try searching for another word or applying a different filter or search'}</Paragraph>
          </EmptyState> : <EmptyState
        layout="horizontal"
        size="small"
        image="waiting"
        title={'There are no deliverables ready for review yet'}
      >
        <Paragraph>
          {
            'It looks like no deliverables have been added for this brief yet. Please check back later or get in touch with your team for more details.'
          }
        </Paragraph>
      </EmptyState>
        }
        renderItem={(item, index) => {
          const category = item?.type?.type;
          const updatedBy = item?.lastUpdatedBy?.displayName;
          const review = item?.reviews;
          const { sharedAt = '', reviewDate = '' } = review[review.length - 1] || {};
          return (
            <Box mb="12px">
              <List.Card key={index} variant="media">
                <DeliverableCard
                  briefName={briefName}
                  briefId={briefId}
                  deliverableType={category}
                  deliverableName={item?.name}
                  updatedBy={updatedBy}
                  originalId={item.originalId}
                  status={item?.clientApprovalStatus}
                  lastUpdated={sharedAt}
                  attentionReq={reviewDate}
                />
              </List.Card>
            </Box>
          );
        }}
      />
    </Box>
  );
};

export default PanelList;
