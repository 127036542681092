import { Page, Tabs } from '@dentsu-ui/components';
import { Helmet } from 'react-helmet';
import { OptionalLink } from './OptionalLink';
import { BriefImages } from './BriefImages';
import { useEffect } from 'react';
import withClientHierarchy from 'hoc/withClientHierarchy';
import { useBriefList } from 'hooks';
import Loader from 'components/Loader';

/**
 * Client settings
 */
const Settings = props => {
  const { setHierarchyHandler, hierarchyData: clientStructure, clientCode } = props;

  const { refetch, loading } = useBriefList();

  useEffect(() => {
    if (clientStructure?.H1?.unrolledAccountIds && clientStructure?.H2?.unrolledAccountIds) {
      const { H1, H2 } = clientStructure;
      setHierarchyHandler(H1.unrolledAccountIds, H2.unrolledAccountIds, '*');
      refetch();
    }
  }, [JSON.stringify(clientStructure), clientCode]);

  if(loading) return <Loader />

  return !loading && (
    <>
      <Helmet title="Client Settings" />
      <Page title="Client Settings">
        <Tabs>
          <Tabs.List>
            <Tabs.Tab label="Brief Images" />
            <Tabs.Tab label="Optional Link" />
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <BriefImages />
            </Tabs.Panel>
            <Tabs.Panel>
              <OptionalLink />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Page>
    </>
  );
};

export default withClientHierarchy(Settings);
