import React from "react";

import { Stack, Box, Paragraph } from "@dentsu-ui/components";


const AudienceBrief = ({briefAudience, geographyData, comments}) => {
  const primaryValue = briefAudience && briefAudience.find((item) => item.isPrimary === true)
  const secondayValue = briefAudience && briefAudience.find((item) => item.isPrimary === false)

  return (
    <>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Primary audience'}</Paragraph>
          <Paragraph>{(primaryValue && primaryValue.audience) || "-"}</Paragraph>
        </Box>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Geography'}</Paragraph>
          <Paragraph>{geographyData || "-"}</Paragraph>
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
        <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>{'Secondary audience'}</Paragraph>
            <Paragraph>{(secondayValue && secondayValue.audience) || "-"}</Paragraph>
        </Box>
        <Box width={[3 / 6]} pr="30px">
        </Box>
      </Stack>
      <Stack justifyContent="space-between" mb={20}>
      <Box width={[3 / 6]} pr="30px">
          <Paragraph isBold={true}>
            {'Additional comments'}
          </Paragraph>
          <Paragraph>{comments || "-"} </Paragraph>
        </Box>
      </Stack>
    </>
  );
};



export default AudienceBrief;
