import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Modal } from '@dentsu-ui/components';
import { clientDetailsQuestion } from './helper';
import { Agreement } from './Agreement';
import { Lead } from './Lead';
import { About } from './About';
import { GET_CLIENT_SUMMARY } from '../graphql/query';
import { useQuery } from '@apollo/client';

/*
 * Client Workflow Hub - Client Structure Modal
 */
export const ClientStructureModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(null);
  const [clientSummary, setClientSummary] = useState('');
  const { clientName } = props;

  useImperativeHandle(ref, () => ({
    isOpen: isOpen => {
      setIsOpen(isOpen);
    },
    data: data => {
      setData(data);
    },
  }));

  function handleOnClose() {
    setIsOpen(false);
    setData(null);
  }

  const handleClientSummary = summary => {
    setClientSummary(summary && summary.getClientSummary.data);
  };

  useQuery(GET_CLIENT_SUMMARY, {
    fetchPolicy: 'network-only',
    onCompleted: summary => handleClientSummary(summary),
  });
  if (data === null || !isOpen) return null;

  return (
    <Modal
      ref={ref}
      canOutsideClickClose={true}
      isOpen={isOpen}
      onClose={handleOnClose}
      portalContainer={document.body}
      usePortal={true}
    >
      <Modal.Header hasCloseButton={true} metadata={clientName} title="Client Structure" />
      <Modal.Tabs>
        <Modal.TabList>
          <Modal.Tab label="Client and agency brand details" />
          <Modal.Tab label="Agreement" />
        </Modal.TabList>
        <Modal.TabPanels>
          <Modal.TabPanel>
            <About
              aboutHeading={clientDetailsQuestion.about}
              type={clientDetailsQuestion.clientType}
              typeData={clientSummary.type}
              agencyType={clientDetailsQuestion.agencyType}
              agencyTypeData={`${clientSummary.country.code} - ${clientSummary.country.name}`}
              sector={clientDetailsQuestion.sector}
              sectorData={clientSummary.sector.name}
              country={clientDetailsQuestion.country}
              countryData={clientSummary.markets}
            />
            <Lead
              leaadHeading={clientDetailsQuestion.leadHeading}
              leadAgency={clientDetailsQuestion.leadAgency}
              leadAgencyData={clientSummary.leadAgency.code}
              supportingHeading={clientDetailsQuestion.supportingHeading}
              supportingAgency={clientDetailsQuestion.supportingAgencies}
              supportingAgencyData={clientSummary.supportingAgencies}
            />
          </Modal.TabPanel>
          <Modal.TabPanel>
            <Agreement
              agreementHeading={clientDetailsQuestion.agreementHeading}
              contDuration={clientDetailsQuestion.contDuration}
              contType={clientDetailsQuestion.contType}
              durationType={clientDetailsQuestion.durationType}
              startDate={clientDetailsQuestion.startDate}
              endDate={clientDetailsQuestion.endDate}
              contractTypeData={clientSummary.contractType}
              durationTypeData={clientSummary.contractDuration.type}
              from={clientSummary.contractDuration.from}
              to={clientSummary.contractDuration.to}
            />
          </Modal.TabPanel>
        </Modal.TabPanels>
      </Modal.Tabs>

      <Modal.Footer>
        <Button variant="tertiary" onClick={handleOnClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
