import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_CLIENT_BANNER } from 'modules/Dashboard/graphql';
import { getClientLogoBanner } from 'modules/helper';


const useClientBanner = () => {
  const [clientBanner, setClientBanner] = useState('');
  const [banner, setBanner] = useState('');

  const handleClientBanner = banner => {
    setClientBanner((banner && banner.getCLPClientBanner.bannerImageUrl) || '');
  };

  useQuery(GET_CLIENT_BANNER, {
    fetchPolicy: 'network-only',
    onCompleted: banner => handleClientBanner(banner),
  });

  useEffect(() => {
    const clientBannerfun = async () => {
      if (clientBanner && clientBanner?.length) {
        const clientLogoImage = await getClientLogoBanner(clientBanner);
        setBanner(clientLogoImage);
      }
    };
    clientBannerfun();
  }, [clientBanner]);


  return {banner};
};

export default useClientBanner;
