import { Toast} from '@dentsu-ui/components';
import axios from 'axios';
import config from '../config';
import { clientCode, tenantId } from 'utils';
import { useRef } from 'react';

  // toast init
  const toast = Toast();

export const finder = (data, key) => {
  if (!data) return { label: '' };
  if (!key || key === 'all') {
    return data;
  }
  if (data.value === key) {
    return data;
  }
  const { children } = data;
  return children ? children.find(item => item.value === key) : { label: '' };
};

export const getBrandLocation = (location, clientHierarchy) => {
  const locationIds = location?.split('_');
  const currentLocationData = locationIds?.reduce(finder, clientHierarchy);
  return currentLocationData?.label || '';
};

export const getAllOriginalId = brief => {
  let originalIds = [];
  brief && brief.forEach(({ originalId }) => originalIds.push(originalId));
  return originalIds;
};

export const getClientLogoBanner = async imageUrl => {
  const storage = localStorage.getItem('okta-token-storage');
  const token = JSON.parse(storage)?.accessToken?.accessToken;
  const logoUrlResponse = await fetch(imageUrl, {
    method: 'GET',
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  if (!logoUrlResponse.ok) {
    return null;
  }

  const imageBlob = await logoUrlResponse.blob();
  const imageObjectURL = URL.createObjectURL(imageBlob);
  return imageObjectURL;
};

export const handleProfilePicture = (ownerEmail, profilePicture) => {
  const profilePic = profilePicture.find(ele => {
    if (ele.email === ownerEmail) {
      return ele.data;
    }
  });
  return profilePic;
};

export const validate = (link, linkError) => {
  const errors = {};
  if (!link || linkError) {
    errors.link = 'URL is required!';
    toast({
      title: 'There is an error while updating optional link',
      status: 'error',
    });
  } else {
    toast({
      title: 'Updated optional link',
      status: 'success',
    });
  }
  return errors;
};

export const getFinancialYear = financialDate => {
  const month = parseInt(financialDate.split('/')[1]);
  const date = parseInt(financialDate.split('/')[0]);
  const tempDate = new Date();
  const currentYear = tempDate.getFullYear();
  const currentMonth = tempDate.getMonth();

  if (currentMonth + 1 < month) {
    return `${currentYear - 1}-${month}-${date}`;
  }
  return `${currentYear}-${month}-${date}`;
};

export const getClientCurrency = clientStructure => {
  const { H1 } = clientStructure || {};
  if (H1) {
    const {
      setting: {
        currency: { code = '' } = {},
      } = {},
    } = H1 || {};
    return code;
  }
};

export const getFinancialDate = clientStructure => {
  const { H1 } = clientStructure || {};
  if (clientStructure?.H1?.setting) {
    const {
      setting: { date },
    } = H1 || {};
    return date;
  }
};

export const getChannelKpiNames = (channelsList, kpiList, srcData) => {
  const newChannelKpi = srcData?.map(({ channelCode = '', kpi = [] }) => {
      const { name: channelName } = channelsList?.find(({ code }) => code === channelCode) || {};
      const newKPI = kpi.map(({ kpiId }) => {
          const { name: kpiName } = kpiList?.find(({ code }) => code === kpiId) || {};
          return {
              kpiId,
              kpiName
          }
      });
      return {
          channelCode,
          channelName,
          kpi: newKPI
      };
  });
  return newChannelKpi
}

export const addZero = (value) => {
  if (value < 10) {
    value = '0' + value;
  }
  return value;
}

export const formatAttachmentName = (fileName) => {
  const splitArray = fileName.split(".");
  const name = splitArray.length === 2 ? splitArray[0] : splitArray[0].slice(0, -2);
  const extension = splitArray.pop();
  return `${name}.${extension}`;
}

export const downloadAttachment = async (url) => {
  const storage = localStorage.getItem('okta-token-storage');
  const token = JSON.parse(storage)?.accessToken?.accessToken;
  const CWH_CODE = 'CWH'
  const obj = { appCode: CWH_CODE, tenantId, clientCode, ...JSON.parse(sessionStorage.getItem("hierarchy")) };
  return axios.get(url,
    {
    responseType: 'blob',
    headers: {
      serviceconfig: JSON.stringify(obj),
      Authorization: `Bearer ${token}`,
    },
  });
};

export const downloadAttachmentCLP = async (url, variables) => {
  const storage = localStorage.getItem('okta-token-storage');
  const token = JSON.parse(storage)?.accessToken?.accessToken;
  const CWH_CODE = 'CLP'
  const obj = { appCode: CWH_CODE, tenantId, clientCode, ...JSON.parse(sessionStorage.getItem("hierarchy")) };
  return axios.post(url,
    {...variables},
    {
    responseType: 'blob',
    headers: {
      serviceconfig: JSON.stringify(obj),
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFormateDate = (attachmentDate) => {
  const date = new Date(attachmentDate);
  const dateOptions = { day: '2-digit', month: 'short', year: 'numeric', time: 'numeric' };
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());
  const formatedDate = `${date.toLocaleString('en-GB', dateOptions)} / ${hour}:${minute}`
  return formatedDate
}

 export const downloadAttachmentFromBlob = (res, documentName) => {
  const blob = new Blob([res.data], { type: res.headers['content-type'] });
  const href = window.URL.createObjectURL(blob);
  const theLink = document.createElement('a');
  theLink.href = href;
  theLink.download = documentName;
  theLink.click();
};

export const downloadAttachmentList = async (
  documentId,
  briefOriginalId,
  documentName,
  setAttachmentDownloadDetail,
) => {
  const url = `${config.campaignServiceKongUrl}/api/briefs/V2/${briefOriginalId}/attachment/${documentId}`;
  setAttachmentDownloadDetail({
    attachmentDownloadLoading: true,
    attachmentIdForDownload: documentId,
  });
  await downloadAttachment(url)
    .then((res) => {
      downloadAttachmentFromBlob(res, documentName);
      toast({
          title: `Document: ${documentName} successfully downloaded`,
          status: "success"
      });
      setAttachmentDownloadDetail({
        attachmentDownloadLoading: false,
        attachmentIdForDownload: documentId,
      });
    })
    .catch(() => {
      toast({
        title: `Download failed for document: ${documentName}`,
        status: "error"
   });
      setAttachmentDownloadDetail({
        attachmentDownloadLoading: false,
        attachmentIdForDownload: documentId,
      });
    });
};


export const firstCharToUpperCase = (string) => {
  if (!string) {
    return "";
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFormatDate = (date) => {
  const tempDate = new Date(date).toDateString().split(' ');
  const day = tempDate[2];
  const month = tempDate[1];
  const year = tempDate[3];
  return `${day} ${month} ${year}`;
};


export const fetchAttachment = async (documentId, documentName, setAttachmentLoadingDetail) => {
  const variables = { attachmentId: documentId };
  const url = `${config.cwhServiceKongUrl}/api/deliverables/download`;
  setAttachmentLoadingDetail({
    attachmentDownloadLoading: true,
    attachmentIdForDownload: documentId,
  });
  await downloadAttachmentCLP(url, variables).then((res) => {
    downloadAttachmentFromBlob(res, documentName);
    toast({
      title: `Document: ${documentName} successfully downloaded`,
      status: "success"
  });
    setAttachmentLoadingDetail({
      attachmentDownloadLoading: false,
      attachmentIdForDownload: documentId,
    });
  }).catch((err) => {
      toast({
          title: `Download failed for document: ${documentName}`,
          status: "error"
      });
    setAttachmentLoadingDetail({
      attachmentDownloadLoading: false,
      attachmentIdForDownload: documentId,
    });
  });
}


export const getFinancialYearDate = (financialDate) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = parseInt(financialDate?.split('/')[1]) - 1;
  const monthEnd = month === 0 ? 12 : month;
  const date = parseInt(financialDate?.split('/')[0]);
  let currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();

  if (currentMonth + 1 < month + 1) {
    currentYear = currentYear - 1;
  }

  return [
    {
      label: `${months[month]} ${currentYear + 2} - ${months[monthEnd - 1]} ${month === 0 ? currentYear + 2 : currentYear + 3}`,
      year: `${currentYear + 2}-${month + 1}-${date}`,
    },
    {
      label: `${months[month]} ${currentYear + 1} - ${months[monthEnd - 1]} ${month === 0 ? currentYear + 1: currentYear + 2}`,
      year: `${currentYear + 1}-${month + 1}-${date}`,
    },
    {
      label: `${months[month]} ${currentYear} - Present`,
      year: `${currentYear}-${month + 1}-${date}`,
    },
    {
      label: `${months[month]} ${currentYear - 1}  - ${months[monthEnd - 1]} ${month === 0 ? currentYear - 1 : currentYear}`,
      year: `${currentYear - 1}-${month + 1}-${date}`,
    },
    {
      label: `${months[month]} ${currentYear - 2}  - ${months[monthEnd - 1]} ${month === 0 ? currentYear - 2 : currentYear - 1}`,
      year: `${currentYear - 2}-${month + 1}-${date}`,
    },
  ];
}