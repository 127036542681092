import React from 'react';
import { Box, IconStatus, Paragraph, Caption, Stack, Link, Divider } from '@dentsu-ui/components';
const Notifications = ({ icon, heading, content, timeAgo, link, isDivider, status}) => {
    return(
        <Stack>
        <Box width={0.2}>
            <Box className="notifyBox" width="45px" height="45px" style={{ background: "#76a0df", borderRadius: "50%"}} display="flex" alignItems="center" justifyContent="center">
          {/* <Icon status={status} icon={icon} /> */}
          <IconStatus status={status} icon={icon} />
            </Box>
        </Box>
        <Box width={0.8}>
          <Stack flexDirection="column">
            <Paragraph isBold>{heading}</Paragraph>
            <Caption>{content}</Caption>
            <Caption isItalic>{timeAgo}</Caption>
            <Link url="#">{link}</Link>
          </Stack>
        {isDivider ? <Divider /> : null}
        </Box>
      </Stack>
    )
}
export default Notifications;
