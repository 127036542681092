import React from "react";
import PropTypes from "prop-types";
import { Loading, Stack } from "@dentsu-ui/components";

const Loader = ({ size, thickness, loaderMessage, ...rest }) => {
  return (
    <Stack justifyContent="center" alignItems="center" m={10} height="90%">
      <Loading size={size} thickness={thickness} {...rest}>
        {loaderMessage}
      </Loading>
    </Stack>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  thickness: PropTypes.string,
};

Loader.defaultProps = {
  size: "xlarge",
  thickness: "2px",
};

export default Loader;
