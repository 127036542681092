import { useState } from 'react';
import { useQuery } from '@apollo/client';
// GQL
import { FETCH_CHANNEL } from 'modules/BriefViewDetail/graphql';


const useChannelKpi = () => {
    const [channelKpiData, setChannelKpiData] = useState([]);
    const handleChannelkpi = channel => {
        const channelData = channel && channel?.getChannels;
        const channelsData = [];
        channelData.forEach(({ groups = [] }) => {
            groups.forEach(({ channels = [] }) => {
                channelsData.push(...channels);
            })
        });
        if (!channelKpiData) {
            setChannelKpiData([]);
          return;
        }
        setChannelKpiData(channelsData);
      };
      
  useQuery(FETCH_CHANNEL, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    onCompleted: result => handleChannelkpi(result),
  });
  return {channelKpiData};
};

export default useChannelKpi;
