import React from 'react';
import { Button, FormField, Modal, Textarea } from '@dentsu-ui/components';

export default function PopupModal({
  isApprove,
  setIsApprove,
  name,
  isRequest,
  title,
  setRequestChange,
  buttonName,
  label,
}) {
  const handleClose = () => {
    setIsApprove(false);
    setRequestChange(false);
  };

  return (
    <Modal isOpen={isApprove || isRequest} onClose={handleClose}>
      <Modal.Header hasCloseButton={true} title={`${title} “${name}”`} />
      <Modal.Body>
        <FormField label={label}>
          <Textarea maxLength={300} />
        </FormField>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>{buttonName}</Button>
      </Modal.Footer>
    </Modal>
  );
}
