import styled from 'styled-components';
import { Link as DclLink, Paragraph, Stack } from '@dentsu-ui/components';

const Wrapper = styled.div`
  background-color: var(--dentsu-color-white);
  margin-block: 2px;
  padding: 16px;
`;

const Body = styled(Stack).attrs({
  flexDirection: 'row',
  alignItems: 'center',
})`
  flex: 1;
`;

const Column = styled(Stack)`
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  gap: var(--dentsu-spacing-8);

  .dentsu-chip {
    margin-block: 0;
    margin-inline: 0;
  }
`;

const Link = styled(DclLink)`
  font-size: var(--dentsu-font-size-12);
  margin-left: var(--dentsu-spacing-12);
`;

const Title = styled(Paragraph)`
  padding-inline: ${({ isSubTask }) => (isSubTask ? 'var(--dentsu-spacing-24) 0' : '0')};
`;

export const Styled = {
  Link,
  Title,
  Wrapper,
  Body,
  Column,
};
