import { Box, Tabs, IconStatus } from '@dentsu-ui/components';
import React from 'react';

import PanelList from './PanelList';

const DeliverableTab = ({ deliverableList, loading, briefId, briefName, refetch }) => {

  const statsData = deliverableList?.stats;
  const listData = deliverableList?.list;

  //shoted data for the tab

  const tabList = statsData?.slice().sort((a, b) => {
    return a.typeCode - b.typeCode;
  });

  const isReviewStatus = (label, list) => {
    const temp = list?.filter(tempData => tempData.type.type === label.type);
    if (temp?.length === 1) {
      return !!(temp[0].clientApprovalStatus === 'inReview');
    }
    return !!temp?.find(status => status.clientApprovalStatus === 'inReview');
  };

  return (
    <>
      <Box mt="50px">
        <Tabs>
          <Tabs.List>
            {tabList?.map(label => (
              <Tabs.Tab
                label={label.type}
                actions={isReviewStatus(label, listData) && <IconStatus status="warning" />}
              />
            )) || []}
          </Tabs.List>
          <Tabs.Panels>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Strategy')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Audience')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Plan')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Activation')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Measurement')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
            <Tabs.Panel>
              <PanelList
                panelData={listData?.filter(element => element.type.type === 'Other')}
                loading={loading}
                briefName={briefName}
                briefId={briefId}
                refetch={refetch}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Box>
    </>
  );
};

export default DeliverableTab;
