import { Paragraph, Stack } from "@dentsu-ui/components";


const getKpi = kpiList => {
    return kpiList?.map((kpi, index) => {
      return (
        <Paragraph>
          {'KPI'} {index + 1} - {kpi.kpiName}
        </Paragraph>
      );
    });
  };

  
export const getChannelAndKpi = (newChannelKpi) => {
    return newChannelKpi?.map((item, index) => {
      const { channelName, kpi: kpiList } = item;
      return (
        <>
          <Stack pb={'10px'}>
            <Paragraph isSecondary isBold>
              {channelName}{' '}
            </Paragraph>
          </Stack>
          <Stack pb={'20px'}>
            <Paragraph>{getKpi(kpiList)}</Paragraph>
          </Stack>
        </>
      );
    });
  };