import React, { createContext, useContext } from 'react';
import { Styled } from './DescriptionList.styles';
import { Caption } from '@dentsu-ui/components';

// Context
const Context = createContext();

// DescriptionList wrapper component - <dl>
function DescriptionList({
  children,
  fontSize = 'small',
  hasSeparator = false,
  hasEndSeparator = false,
  isInline = false,
  ...rest
}) {
  return (
    <Context.Provider value={{ fontSize, hasSeparator, hasEndSeparator, isInline }}>
      <Styled.DescriptionList isInline={isInline} {...rest}>
        {children}
      </Styled.DescriptionList>
    </Context.Provider>
  );
}

// DescriptionList term component - <dt>
function Term({ children, ...rest }) {
  const { fontSize, hasSeparator, hasEndSeparator, isInline } = useContext(Context);
  return (
    <Caption
      as={Styled.Term}
      fontSize={fontSize}
      hasEndSeparator={hasEndSeparator}
      hasSeparator={hasSeparator}
      isInline={isInline}
      isSecondary
      {...rest}
    >
      {children}
    </Caption>
  );
}

// DescriptionList detail component - <dd>
function Detail({ children, ...rest }) {
  const { fontSize, hasSeparator, hasEndSeparator, isInline } = useContext(Context);
  return (
    <Caption
      as={Styled.Detail}
      fontSize={fontSize}
      hasEndSeparator={hasEndSeparator}
      hasSeparator={hasSeparator}
      isInline={isInline}
      {...rest}
    >
      {children}
    </Caption>
  );
}

DescriptionList.Term = Term;
DescriptionList.Detail = Detail;

export { DescriptionList };
