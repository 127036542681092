import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { FETCH_TEAMS_PROFILE } from 'modules/Dashboard/graphql';
import useBriefList from './useBriefList';


const useOwnerProfile = () => {
  const [profilePicture, setProfilePicture] = useState([]);
  const [profileName, setProfileName] = useState([]);

  const { ownerEmail } = useBriefList();

  // FETCH_TEAMS_PROFILE
  const handleUserProfileData = (profile) => {
    const {
      getCLPAllUsersProfileByEmails: {
        data: { usersProfileName, usersProfilePicture },
      },
    } = profile;

    if(usersProfilePicture){setProfilePicture(usersProfilePicture)};
    if (usersProfileName) { setProfileName(usersProfileName) }
  }

  useQuery(FETCH_TEAMS_PROFILE, {
    variables: { emails: ownerEmail },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (profile) => handleUserProfileData(profile),
   })
   
  return { profileName ,profilePicture };
};

export default useOwnerProfile;
