import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Menu } from '@dentsu-ui/components';
import { useHistory } from 'react-router-dom';
// import { show, hide } from './helper';
import { useOktaAuth } from '@okta/okta-react';
import { switchClientAtom } from 'store';
import { useAtom } from 'jotai';

import {
  StyledMenuEmail,
  StyleProfileMenuButton,
  ProfileSwitcherSection,
  ProfileSwitcherContainer,
  ProfileSwitcherCurtain,
} from '../styles';

const ProfileMenu = props => {
  const history = useHistory();

  const { userData } = props;
  const [showMenu, setShowMenu] = useState(false);
  const nodeTwo = useRef();

  const handleClick = e => {
    if (nodeTwo.current.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  };

  const { oktaAuth } = useOktaAuth();
  const logout = async () => {
    console.log('hello');
    await oktaAuth.signOut();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const [, setSwitchClient] = useAtom(switchClientAtom);

  return (
    <ProfileSwitcherContainer ref={nodeTwo}>
      <ProfileSwitcherSection isOpen={!showMenu}>
        <Menu>
          <StyleProfileMenuButton
            className="custom-menu"
            icon="chevron-down"
            variant="ghost"
            data-testid="select-profile"
            onClick={() => {
              setShowMenu(!showMenu);
            }}
          />
          <Menu.List>
            <StyledMenuEmail icon="user" isDisabled>
              {userData.userEmail}
            </StyledMenuEmail>
            <Menu.Item icon="swap-horizontal" onClick={() => setSwitchClient(true)}>
              Switch Client
            </Menu.Item>
            <Menu.Divider />
            <Menu.Group title="Apps">
              <Menu.Item icon="dashboard" onClick={() => history.push('/')}>
                Client Portal
              </Menu.Item>
              <Menu.Item icon="properties" onClick={() => history.push('/briefs')}>
                Client Workflow Hub
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Group title="Administration">
              <Menu.Item icon="settings" onClick={() => history.push('/settings')}>
                Client Settings
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Item onClick={logout} icon="log-out">
              Logout
            </Menu.Item>
          </Menu.List>
        </Menu>
      </ProfileSwitcherSection>
      {showMenu && <ProfileSwitcherCurtain data-testid="app-curtain" onClick={() => setShowMenu(!showMenu)} />}
    </ProfileSwitcherContainer>
  );
};

ProfileMenu.propTypes = {
  userData: PropTypes.object.isRequired,
};

export default ProfileMenu;
