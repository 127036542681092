import { Styled } from './AppFooter.styles';
import { Button } from '@dentsu-ui/components';
import logo from 'static/images/dentsu-logo.svg';

// App Footer Component
function AppFooter() {
  return (
    <Styled.AppFooter>
      <Styled.Nav>
        <Styled.NavPrimary>
          <Button size="small" variant="ghost" iconLeft="chat">
            Feedback
          </Button>
        </Styled.NavPrimary>
        <Styled.NavSecondary>
          <span>Cookie policy</span>
          <span>Cookie settings</span>
          <span>Privacy notice</span>
          <span>EULA</span>
          <span>Release notes</span>
        </Styled.NavSecondary>
      </Styled.Nav>
      <Styled.Brand>
        <Styled.Logo src={logo} alt="Dentsu" />
      </Styled.Brand>
    </Styled.AppFooter>
  );
}

export { AppFooter };
