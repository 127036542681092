import React, { useState } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import AuthContext from '../../contexts/authContext';

const OktaAuthComponent = props => {
  const { children, oktaAuth, authState } = props;
  const {
    token: { decode },
  } = oktaAuth;
  const {
    isAuthenticated,
    accessToken: { accessToken },
  } = authState;
  const userClaims = decode(accessToken);

  const initialState = {
    auth: { ...authState, ...oktaAuth },
    authenticated: isAuthenticated,
    token: accessToken,
    userId: userClaims && userClaims.payload && userClaims.payload.uid,
    userClaims,
  };
  const [value] = useState(initialState);

  localStorage.setItem('isAuthenticated', isAuthenticated);
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

OktaAuthComponent.propTypes = {
  auth: PropTypes.object,
  children: PropTypes.object,
};

export default withOktaAuth(OktaAuthComponent);
