// export const toDataURL = (url, callback) => {
//     var xhr = new XMLHttpRequest();
//     xhr.onload = function() {
//       var reader = new FileReader();
//       reader.onloadend = function() {
//         callback(reader.result);
//       }
//       reader.readAsDataURL(xhr.response);
//     };
//     xhr.open('GET', url);
//     xhr.open('Access-Control-Allow-Origin', '*');
//     xhr.responseType = 'blob';
//     xhr.send();
//   }
//   toDataURL('https://png.pngtree.com/png-clipart/20190918/ourmid/pngtree-pink-watercolor-brushes-171474-png-image_1733978.jpg')

  export const handleValidate = (imageFile) => {
    const { file } = imageFile;
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        if (img.width > 300 || img.height > 300) {
          return reject("Image couldn’t be uploaded, dimensions should be 300px or smaller");
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = err => reject(err);
        reader.onloadend = () => {
        const imageUrl = reader.result;
        const [, , imageData] = imageUrl && imageUrl.match(/^data:([A-Za-z-+/]+);base64,(.+)$/);
        if (imageData.charAt(0) !== 'i') {
          return reject("Wrong file format, only .png allowed");
        }
          return resolve(reader.result);
        }
        return reader;
      };
      return img;
    });
  };

  export const getHash = (input) => {
    let hash = 0;
    let len = input.length;
    for (var i = 0; i < len; i++) {
    hash = (hash << 5) + hash + input.charCodeAt(i);
    hash |= 0;
    }
    return hash;
    }

  
  