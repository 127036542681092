import styled from 'styled-components';

const AppWrapper = styled.div`
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  grid-template-areas: 'header' 'main' 'footer';
`;

export const Styled = { AppWrapper };
