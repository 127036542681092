import { useQuery } from '@apollo/client';
import { Card, Icon, Box, Stack, Link, Button } from '@dentsu-ui/components';
import Loader from 'components/Loader';
import { DeliverableDetails } from 'modules/DeliverableDetails';
import { FETCH_DELIVERABLES_DATA } from 'modules/DeliverableDetails/gql';
import React, { useState } from 'react';
import GetAttentionReq from '../GetAttentionReq';
import GetDeliverableName from '../GetDeliverableName';
import GetMetaInfo from '../GetMetaInfo';
import GetStatus from '../GetStatus';

export default function DeliverableCard({
  deliverableName,
  deliverableType,
  updatedBy,
  status,
  lastUpdated,
  attentionReq,
  briefName,
  originalId,
}) {
  const [showDeliverable, setShowDeliverable] = useState(false);
  const [deliverableList, setDeliverableList] = useState([]);

  const handleDeliverable = data => {
    setDeliverableList(data && data?.getDeliverablesById?.data);
  };

  const { loading: descriptionLoading } = useQuery(FETCH_DELIVERABLES_DATA, {
    variables: {
      id: originalId,
    },
    fetchPolicy: 'network-only',
    onCompleted: data => handleDeliverable(data),
  });

  if (descriptionLoading) {
    return <Loader />;
  }
  const statuses = ['inReview', 'changeRequested', 'approved'];

  return (
    <>
      <Box padding="8px 18px 8px 18px">
        <Stack justifyContent="center" alignItems="center">
          <Box flexGrow="1" width="34%">
            <Card.Metadata>{deliverableType}</Card.Metadata>
            <GetDeliverableName deliverableName={deliverableName} />
          </Box>
          <Box width="22%" alignContent="flex-start" ml="10px">
            <GetMetaInfo updatedBy={updatedBy} lastUpdated={lastUpdated} />
          </Box>
          <Box width="15%" ml="20px" minWidth="max-content">
            <GetStatus status={status} />
          </Box>
          <Box width="20%">
            {attentionReq && status !== statuses[2] && <GetAttentionReq attentionReq={attentionReq} />}
          </Box>
          <Box width="12%" ml="10px">
            <Stack flex justifyContent="flex-end">
              <Button variant="ghost" iconRight="chevron-right" size="small" onClick={() => setShowDeliverable(true)}>
                View details
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Box>

      {showDeliverable && (
        <DeliverableDetails
          setShowDeliverable={setShowDeliverable}
          showDeliverable={showDeliverable}
          briefName={briefName}
          loading={descriptionLoading}
          status={status}
          deliverableList={deliverableList}
          deliverableType={deliverableType}
        />
      )}
    </>
  );
}
