// import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Subheading, Stack, Box, Paragraph } from '@dentsu-ui/components';
import { getContractType, getDurationType, getFormattedDate } from './helper';
export const Agreement = ({ agreementHeading, contDuration, contType, durationType, startDate, endDate, contractTypeData, durationTypeData, from, to }) => {

  return (
    <>
      <Box mb="10px">
        <Subheading isSmaller isLight>
          {contDuration}
        </Subheading>
      </Box>
      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {contType}
        </Box>
        <Box width={[1 / 2]}>
          {' '}
          <Paragraph isSemibold>{getContractType(contractTypeData)}</Paragraph>
        </Box>
      </Stack>

      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {durationType}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{getDurationType(durationTypeData)}</Paragraph>
        </Box>
      </Stack>

      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {startDate}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{getFormattedDate(from)}</Paragraph>
        </Box>
      </Stack>

      <Stack mb={1}>
        <Box width={[1 / 2]} pr={5}>
          {endDate}
        </Box>
        <Box width={[1 / 2]}>
          <Paragraph isSemibold>{getFormattedDate(to)}</Paragraph>
        </Box>
      </Stack>
    </>
  );
};
