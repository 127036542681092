import { useState } from 'react';
import { useQuery } from '@apollo/client';
// GQL
import { FETCH_BRIEF_LIST} from '../modules/Dashboard/graphql/query';


const useBriefList = (agency = '') => {
    const [briefDataList, setBriefDataList] = useState([]);
    const [briefPageSize, setBriefPageSize] = useState(10);

    const handleBriefData = data => {
        const briefData = data && data?.getBriefs;
        if (!briefData) {
          setBriefDataList([]);
          return;
        }
        setBriefDataList(briefData?.briefs);
      };
      
  const { data: { getBriefs: { totalCount = 0 } = {} } = {}, loading, refetch } = useQuery(FETCH_BRIEF_LIST, {
    variables: {
      page: 1,
      pageSize: briefPageSize,
      search: {
        name: '',
        status: '',
      },
      sortOrder: 'descending',
      ...(agency && agency !== '*' ? { leadAgency: agency } : {})
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    skip: !JSON.parse(sessionStorage.getItem('hierarchy'))?.h1,
    onCompleted: result => handleBriefData(result),
  });
  const ownerEmail = briefDataList?.map(email => email.ownerEmail);
  return {loading, refetch, totalCount, briefDataList, briefPageSize , ownerEmail, setBriefPageSize};
};

export default useBriefList;
