import { useClientHierarchy } from 'hooks';
import React from 'react';


const withClientHierarchy = (WrappedComponent) => (props) => {
  const {clientStructure, clientCode, clientName } = useClientHierarchy({});
  const initialValueH1 = clientStructure?.H1?.unrolledAccountIds;
  const initialValueH2 = clientStructure?.H2?.unrolledAccountIds;
  const setHierarchyHandler = (h1 = initialValueH1, h2 = initialValueH2, agency = '*') => {
    sessionStorage.setItem('hierarchy', JSON.stringify({ h1, h2, agency: agency || '*' }));
  };
  return (
    <>
      <WrappedComponent
        setHierarchyHandler={setHierarchyHandler}
        hierarchyData={clientStructure}
        agencyData={clientStructure?.agency}
        initialValueH1={initialValueH1}
        initialValueH2={initialValueH2}
        clientCode={clientCode}
        clientName={clientName}
        {...props}
      />
    </>
  );
};
export { withClientHierarchy as default };


