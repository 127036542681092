import { Link } from 'react-router-dom';
import { Icon, Divider, Avatar , Menu,Box, Tabs} from '@dentsu-ui/components';
import { Styled } from './AppHeader.styles';
import { useAuth } from '../../contexts/authContext';
import ProfileMenu from '../ProfileMenu';
import { StyledChromeSwitcher } from '../styles';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { notificationData } from 'constants';
import Notifications from 'components/Notification/Notifications';
import { useClientHierarchy } from 'hooks';

// App Header Component
const AppHeader = () => {
  const [userData, setUserData] = useState({});
  const { auth: { token: { getUserInfo = () => {} } = {} } = {} } = useAuth() || {};

  const { clientName } = useClientHierarchy({});
  const [moduleName, setModuleName] = useState();

  useEffect(() => {
    async function getUserData() {
      const user = await getUserInfo();
      const { email = '', name = '' } = user || {};
      if (!Object.keys(userData).length) {
        setUserData({ userName: name, userEmail: email });
      }
    }
    if (!Object.keys(userData).length) {
      getUserData();
    }
  }, [getUserInfo, userData]);

  function useModuleView() {
    let location = useLocation();
    let pathname = location.pathname;

    useEffect(() => {
      if (pathname.startsWith('/client-portal')) {
        setModuleName('Client Portal');
      }

      if (pathname.startsWith('/client-workflow-hub')) {
        setModuleName('Client Workflow Hub');
      }

      if (pathname.startsWith('/settings')) {
        setModuleName('Client Settings');
      }
    }, [pathname]);
  }

  useModuleView();

  return (
    <Styled.AppHeader>
      <Styled.Home>
        <Link to="/">
          <img src="/images/dentsu-connect-home.png" alt="dentsu connect" width="32" />
        </Link>
      </Styled.Home>
      <Styled.Title>
        <Link to="/">
          <img src="/images/dentsu-connect-logo.svg" alt="dentsu connect" height="14" />
        </Link>

        {moduleName && (
          <>
            <Divider orientation="vertical" isFlexChild style={{ background: '#60607d' }} />
            <Styled.PrimaryTitle>{moduleName}</Styled.PrimaryTitle>
          </>
        )}
        {clientName && (
          <>
            <Styled.SecondaryTitle>{clientName}</Styled.SecondaryTitle>
          </>
        )}
      </Styled.Title>
      <Styled.Utilities>
        {/* <Icon size={20} icon="notifications" /> */}
        <Menu placement="auto">
          <Styled.IconButton>
            <Menu.Button size="medium" icon={'notifications'}></Menu.Button>
          </Styled.IconButton>
          <Box p="20px" width="350px">
            <Tabs>
              <Tabs.List>
                <Tabs.Tab label="Notifications" />
              </Tabs.List>
              <Tabs.Panels>
                <Tabs.Panel>
                  {notificationData &&
                    notificationData.map(({ icon, heading, content, timeAgo, link, divider, status }, index) => {
                      return (
                        <Notifications
                          key={`notification-${index}`}
                          icon={icon}
                          content={content}
                          heading={heading}
                          timeAgo={timeAgo}
                          status={status}
                          link={link}
                          isDivider={divider}
                        />
                      );
                    })}
                </Tabs.Panel>
              </Tabs.Panels>
            </Tabs>
          </Box>
        </Menu>
        <Icon size={20} icon="help" />
        <Styled.User>
          <Avatar size={'xlarge'} name={userData.userName} />
          <StyledChromeSwitcher>
            <ProfileMenu userData={userData} />
          </StyledChromeSwitcher>
        </Styled.User>
      </Styled.Utilities>
    </Styled.AppHeader>
  );
};

export { AppHeader };
