import gql from "graphql-tag";

export const SCAN_IMAGE = gql `
mutation uploadClientLogo($imageId:String!, $scanImage:Boolean! , $imageUrl:String ){
  uploadClientLogo(imageId:$imageId , scanImage: $scanImage, imageUrl:$imageUrl){
      clientCode
      isValidImage
      message
      data
      status
  }
}
`;

export const UPLOAD_IMAGE = gql `
mutation uploadClientLogo($imageId:String!, $scanImage:Boolean!, $briefId:String ){
  uploadClientLogo(imageId:$imageId , scanImage: $scanImage,briefId:$briefId ){
      clientCode
      isValidImage
      message
      data
      status
  }
}
`;

