import gql from "graphql-tag";

export const FETCH_DELIVERABLES_DATA = gql`
  query getDeliverablesById($id: ID!) {
    getDeliverablesById(id: $id) {
    data{
        originalId
        majorVersion
        minorVersion
        name
        brief
        task
        clientApprovalStatus
        type {
            type
            typeCode
        }
        description
        attachments {
            version
            fileName
            fileExtension
            fileSize
            originalId
          }
       reviews {
        originalId
        feedback
        reviewDate
        createdBy
        createdAt
        reviewedBy
        reviewedAt
        sharedAt
        reviewDate
        reviewer {
          mail
          displayName
        }
        sharedBy{
            displayName
            mail
          }
      }
  }
}
}
`;